import {NavLink} from 'react-router-dom';

const ResponsiveNavLink = ({children, ...props }) => (
<NavLink
    {...props}
    className={({isActive}) => (isActive
      ? 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium ' +
      'leading-5 focus:outline-none transition duration-150 ease-in-out ' +
      'border-indigo-400 text-white bg-indigo-900 dark:bg-indigo-900' + // Change text color to white
      ' dark:text-white dark:focus:text-indigo-500' +
      ' focus:text-white focus:bg-indigo-800 focus:border-indigo-700' // Ensure focus state is also white
      : 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium ' +
      'leading-5 focus:outline-none transition duration-150 ' +
      'ease-in-out border-transparent text-gray-600 dark:text-gray-300' +
      ' hover:text-gray-800 dark:hover:text-white' +
      ' hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300' +
      ' focus:text-gray-800 dark:focus:text-white' +
      ' focus:bg-gray-50 dark:focus:bg-gray-700 focus:border-gray-300')
    }>
    {children}
</NavLink>
)

export const ResponsiveNavButton = props => (
  <button
    className="block w-full pl-3 pr-4 py-2 border-l-4 text-left
     text-base font-medium leading-5 focus:outline-none transition
     duration-150 ease-in-out border-transparent text-gray-600
     hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300
     focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300
     dark:text-gray-300 dark:hover:text-gray-200 dark:hover:bg-gray-700
     dark:hover:border-gray-700 dark:focus:text-gray-200
     dark:focus:bg-gray-700 dark:focus:border-gray-700"
    {...props}
  />
)

export default ResponsiveNavLink
