import React, { useEffect, useRef, useState } from 'react';

interface DataPoint {
    xLabel: string;
    yLabel: string;
    xRating: number;
    yRating: number;
    label: string;
    url?: string;
}

interface Point {
    x: number;
    y: number;
}

interface QuadrantChartProps {
    maxWidth?: number;
    aspectRatio?: number;
    xLabels: string[];
    yLabels: string[];
    dataPoints: DataPoint[];
    filterType?: 'rated' | 'unrated' | null;
}

const QuadrantChart: React.FC<QuadrantChartProps> = ({
    maxWidth = 900,
    aspectRatio = 0.44,
    xLabels,
    yLabels,
    dataPoints,
    filterType = null
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [hoveredPoint, setHoveredPoint] = useState<Point | null>(null);
    const [isPopupHovered, setIsPopupHovered] = useState(false);

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                const containerWidth = Math.min(containerRef.current.offsetWidth, maxWidth);
                setDimensions({
                    width: containerWidth,
                    height: containerWidth * aspectRatio
                });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [maxWidth, aspectRatio]);

    const margin = { 
        top: dimensions.height * 0.1, 
        right: dimensions.width * 0.05, 
        bottom: dimensions.height * 0.15, 
        left: dimensions.width * 0.15
    };
    
    const chartWidth = dimensions.width - margin.left - margin.right;
    const chartHeight = dimensions.height - margin.top - margin.bottom;

    const numRows = yLabels.length;
    const rowHeight = chartHeight / numRows;
    
    const numCols = xLabels.length;
    const colWidth = chartWidth / numCols;

    const getPointCoordinates = (point: DataPoint): Point => {
        const xIndex = xLabels.indexOf(point.xLabel);
        const yIndex = yLabels.slice().reverse().indexOf(point.yLabel);
        
        if (xIndex === -1 || yIndex === -1) return { x: 0, y: 0 };

        // Adjust ratings to keep points within boundaries
        const adjustRating = (rating: number) => {
            if (rating === 0) return 0.1; // Slightly above bottom
            if (rating === 5) return 4.9; // Slightly below top
            return rating;
        };

        const xRating = adjustRating(point.xRating);
        const yRating = adjustRating(point.yRating);

        const xOffset = (xRating / 5) * colWidth;
        const yOffset = ((5 - yRating) / 5) * rowHeight;

        return {
            x: xIndex * colWidth + xOffset,
            y: yIndex * rowHeight + yOffset
        };
    };

    const groupOverlappingPoints = () => {
        const groups: { point: Point; dataPoints: DataPoint[] }[] = [];
        const threshold = 10;

        dataPoints.forEach(dataPoint => {
            const coords = getPointCoordinates(dataPoint);
            
            const existingGroup = groups.find(group => 
                Math.abs(group.point.x - coords.x) < threshold && 
                Math.abs(group.point.y - coords.y) < threshold
            );

            if (existingGroup) {
                existingGroup.dataPoints.push(dataPoint);
            } else {
                groups.push({
                    point: coords,
                    dataPoints: [dataPoint]
                });
            }
        });

        return groups;
    };

    const pointGroups = groupOverlappingPoints();

    const isPointHovered = (point: Point) => {
        if (!hoveredPoint) return false;
        const threshold = 10;
        return Math.abs(point.x - hoveredPoint.x) < threshold && 
               Math.abs(point.y - hoveredPoint.y) < threshold;
    };

    // Calculate popup position to ensure it's visible
    const getPopupPosition = (point: Point, itemCount: number) => {
        const popupWidth = 180;
        const itemHeight = 32;
        const popupHeight = itemCount * itemHeight + 12;
        const padding = -5;

        let x = point.x + padding;
        let y = point.y - popupHeight / 2;

        // Ensure popup doesn't go off the right edge
        if (x + popupWidth > chartWidth) {
            x = point.x - popupWidth - padding;
        }

        // Ensure popup doesn't go off the top or bottom
        if (y < 0) {
            y = padding;
        } else if (y + popupHeight > chartHeight) {
            y = chartHeight - popupHeight - padding;
        }

        return { x, y };
    };

    if (dimensions.width === 0) return <div ref={containerRef} />;

    return (
        <div ref={containerRef} className="w-full relative">
            <svg width={dimensions.width} height={dimensions.height}>
                <g transform={`translate(${margin.left},${margin.top})`}>
                    {/* Grid lines */}
                    {Array.from({ length: numCols + 1 }, (_, i) => (
                        <line
                            key={`x-grid-${i}`}
                            x1={i * colWidth}
                            y1={0}
                            x2={i * colWidth}
                            y2={chartHeight}
                            stroke="#ccc"
                            strokeDasharray="2,2"
                        />
                    ))}
                    {Array.from({ length: numRows + 1 }, (_, i) => (
                        <line
                            key={`y-grid-${i}`}
                            x1={0}
                            y1={i * rowHeight}
                            x2={chartWidth}
                            y2={i * rowHeight}
                            stroke="#ccc"
                            strokeDasharray="2,2"
                        />
                    ))}

                    {/* Border */}
                    <rect
                        x={0}
                        y={0}
                        width={chartWidth}
                        height={chartHeight}
                        fill="none"
                        stroke="black"
                        strokeWidth={1}
                    />

                    {/* X-axis labels */}
                    {xLabels.map((label, i) => (
                        <text
                            key={`x-label-${i}`}
                            x={(i + 0.5) * colWidth}
                            y={chartHeight + 25}
                            textAnchor="middle"
                            className="text-sm dark:fill-white"
                        >
                            {label}
                        </text>
                    ))}

                    {/* Y-axis labels */}
                    {yLabels.slice().reverse().map((label, i) => (
                        <text
                            key={`y-label-${i}`}
                            x={-15}
                            y={(i + 0.5) * rowHeight}
                            textAnchor="end"
                            dominantBaseline="middle"
                            className="text-sm dark:fill-white"
                        >
                            {label}
                        </text>
                    ))}

                    {/* Data points with hover effects */}
                    {pointGroups.map((group, groupIndex) => {
                        const isActive = isPointHovered(group.point);
                        const isRated = group.dataPoints[0].xRating !== 0 || group.dataPoints[0].yRating !== 0;
                        const shouldShow = filterType === null || 
                            (filterType === 'rated' && isRated) || 
                            (filterType === 'unrated' && !isRated);
                        const opacity = (hoveredPoint && !isActive) || !shouldShow ? 0.2 : 1;

                        // Calculate if point is in the rightmost section (last 25% of chart width)
                        const isRightSection = group.point.x > chartWidth * 0.75;

                        return shouldShow ? (
                            <g key={groupIndex}>
                                {/* Circle with hover area */}
                                <g 
                                    onMouseEnter={() => setHoveredPoint(group.point)}
                                    onMouseLeave={() => {
                                        if (!isPopupHovered) {
                                            setHoveredPoint(null);
                                        }
                                    }}
                                >
                                    {/* Invisible larger circle for better hover */}
                                    <circle
                                        cx={group.point.x}
                                        cy={group.point.y}
                                        r={8}
                                        fill="transparent"
                                        style={{ cursor: 'pointer' }}
                                    />
                                    {/* Visible circle */}
                                    <circle
                                        cx={group.point.x}
                                        cy={group.point.y}
                                        r={4}
                                        fill={group.dataPoints[0].xRating === 0 && group.dataPoints[0].yRating === 0 ? "#f87171" : "#00f"}
                                        className={`dark:fill-${group.dataPoints[0].xRating === 0 && group.dataPoints[0].yRating === 0 ? 'red' : 'blue'}-400 transition-all duration-150 ${isActive ? 'stroke-blue-500 stroke-2' : ''}`}
                                        opacity={opacity}
                                    />
                                </g>

                                {/* Always show label or count */}
                                <text
                                    x={isRightSection ? group.point.x - 8 : group.point.x + 8}
                                    y={group.point.y}
                                    textAnchor={isRightSection ? "end" : "start"}
                                    className="text-xs dark:fill-white"
                                    opacity={opacity}
                                    pointerEvents="none"
                                >
                                    {group.dataPoints.length === 1 ? group.dataPoints[0].label : `(${group.dataPoints.length})`}
                                </text>

                                {/* Popup */}
                                {isActive && (
                                    <foreignObject
                                        x={getPopupPosition(group.point, group.dataPoints.length).x}
                                        y={getPopupPosition(group.point, group.dataPoints.length).y}
                                        width={180}
                                        height={group.dataPoints.length * 32 + 12}
                                    >
                                        <div 
                                            className="bg-white dark:bg-slate-800 rounded-lg shadow-lg border border-gray-200 dark:border-slate-700 p-1.5"
                                            onMouseEnter={() => setIsPopupHovered(true)}
                                            onMouseLeave={() => {
                                                setIsPopupHovered(false);
                                                setHoveredPoint(null);
                                            }}
                                        >
                                            {group.dataPoints.map((point, i) => (
                                                <div 
                                                    key={i}
                                                    className={`
                                                        flex items-center justify-between
                                                        px-2 py-1
                                                        ${i !== group.dataPoints.length - 1 ? 'border-b border-gray-100 dark:border-slate-700' : ''}
                                                        text-gray-800 dark:text-gray-200
                                                        hover:bg-gray-50 dark:hover:bg-slate-700
                                                        transition-colors duration-150
                                                        rounded text-xs
                                                    `}
                                                >
                                                    <span className="truncate mr-1">{point.label}</span>
                                                    {point.url && (
                                                        <a
                                                            href={point.url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="shrink-0 ml-1 px-1.5 py-0.5 text-xs bg-blue-500 hover:bg-blue-600 text-white rounded transition-colors duration-150"
                                                        >
                                                            Visit
                                                        </a>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </foreignObject>
                                )}
                            </g>
                        ) : null;
                    })}
                </g>
            </svg>
        </div>
    );
};

export default QuadrantChart;
