import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';

const QRCodeGenerator = () => {
    const [pathToSource, setPathToSource] = useState('documentation/framework/cheatsheet');
    const [hashToSource, setHashToSource] = useState('cheatsheet');
    const [campaignId, setCampaignId] = useState('');
    const [campaignSource, setCampaignSource] = useState('');
    const [campaignMedium, setCampaignMedium] = useState('qrcode');
    const [campaignName, setCampaignName] = useState('');
    const [url, setUrl] = useState('');
    const [showQRCode, setShowQRCode] = useState(false);

    useEffect(() => {
        updateURL();
    }, [pathToSource, hashToSource, campaignId, campaignSource, campaignMedium, campaignName]);

    const updateURL = () => {
        let newUrl = 'https://craftingaiprompts.org/';
        if (pathToSource) newUrl += pathToSource;

        const params = new URLSearchParams();
        if (campaignId) params.append('utm_id', campaignId);
        if (campaignSource) params.append('utm_source', campaignSource);
        if (campaignMedium) params.append('utm_medium', campaignMedium);
        if (campaignName) params.append('utm_campaign', campaignName);

        if (params.toString()) {
            newUrl += `?${params.toString()}`;
        }

        if (hashToSource) newUrl += `#${hashToSource}`;

        setUrl(newUrl);
    };

    const handleGenerate = () => {
        setShowQRCode(true);
    };

    const handleDownload = () => {
        const svg = document.getElementById('qr-code');
        if (svg) {
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx?.drawImage(img, 0, 0);
                const pngFile = canvas.toDataURL('image/png');
                const downloadLink = document.createElement('a');
                downloadLink.download = 'qrcode_'+campaignId+'.png';
                downloadLink.href = pngFile;
                downloadLink.click();
            };
            img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
        }
    };

    return (
        <div className="w-full max-w-2xl mx-auto rounded-lg p-6"> {/* Added dark mode styles */}
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Custom QR Code Generator</h2> {/* Added dark text color */}
            <div className="space-y-4">
                <input
                    type="text"
                    value={pathToSource}
                    onChange={(e) => setPathToSource(e.target.value)}
                    placeholder="Path to source (optional)"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:text-white" // Added dark mode styles
                />
                <input
                    type="text"
                    value={hashToSource}
                    onChange={(e) => setHashToSource(e.target.value)}
                    placeholder="Hash to source (optional) (for example: cheatsheet)"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:text-white" // Added dark mode styles
                />
                <input
                    type="text"
                    value={campaignId}
                    onChange={(e) => setCampaignId(e.target.value)}
                    placeholder="Campaign ID (example: workshop_COMPNAME_ID)"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:text-white" // Added dark mode styles
                />
                <input
                    type="text"
                    value={campaignSource}
                    onChange={(e) => setCampaignSource(e.target.value)}
                    placeholder="Campaign Source (example: COMPNAME)"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:text-white" // Added dark mode styles
                />
                <input
                    type="text"
                    value={campaignMedium}
                    onChange={(e) => setCampaignMedium(e.target.value)}
                    placeholder="Campaign Medium (example: qrcode)"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:text-white" // Added dark mode styles
                />
                <input
                    type="text"
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                    placeholder="Campaign Name (can be the same as the campaign ID without ID)"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:text-white" // Added dark mode styles
                />
                <div className="text-sm font-medium break-all bg-slate-300 dark:bg-slate-700 p-5"> {/* Added dark mode styles */}
                    <p className={"mb-2"}>Generated URL:</p>
                    <p className={"text-gray-600 dark:text-gray-300 mb-1"}><a href={url} target={"_blank"} rel="noreferrer">{url}</a></p>
                </div>
                {showQRCode && (
                    <div className="flex justify-center">
                        <QRCodeSVG
                            id="qr-code"
                            value={url}
                            size={200}
                            level="H"
                            includeMargin={true}
                        />
                    </div>
                )}
            </div>
            <div className="mt-6 space-y-2">
                {!showQRCode && <button
                    onClick={handleGenerate}
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-blue-700 dark:hover:bg-blue-600" // Added dark mode styles
                >
                    Generate QR Code
                </button>}
                {showQRCode && (
                    <button
                        onClick={handleDownload}
                        className="w-full bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 dark:bg-green-700 dark:hover:bg-green-600" // Added dark mode styles
                    >
                        Download QR Code
                    </button>
                )}
            </div>
        </div>
    );
};

export default QRCodeGenerator;