import AuthCard from '../../components/dashboard/AuthCard'
import AuthSessionStatus from '../../components/dashboard/AuthSessionStatus'
import AuthValidationErrors from '../../components/dashboard/AuthValidationErrors'
import Button from '../../components/dashboard/Button'
import GuestLayout from '../../components/dashboard/Layouts/GuestLayout'
import Input from '../../components/dashboard/Input'
import Label from '../../components/dashboard/Label'
import { useAuth } from '../../hooks/auth'
import { useEffect, useState } from 'react'
import {Link, useParams} from 'react-router-dom';
import {ReactComponent as Logo} from "../../assets/img/logo/crafting-ai-logo.svg";

const PasswordReset = () => {
  const params = useParams()
  const { resetPassword } = useAuth({ middleware: 'guest' })

  const { token: urlToken } = useParams();
  const [token, setToken] = useState(urlToken);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password_confirmation, setPasswordConfirmation] = useState('')
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(null)

  const submitForm = event => {
    event.preventDefault()
    resetPassword({
      email,
      password,
      password_confirmation,
      setErrors,
      setStatus
    })
  }

  useEffect(() => {
    setEmail(params.email || '')
  }, [params.email])

  return (
    <GuestLayout>
      <AuthCard
        logo={
          <Link to="/">
            <Logo />
          </Link>
        }>
        {/* Session Status */}
        <AuthSessionStatus className="mb-4" status={status} />
        {/* Validation Errors */}
        <AuthValidationErrors className="mb-4" errors={errors} />
        <form onSubmit={submitForm}>
          {/* Key Address */}
          <div className='hidden'>
            <Label htmlFor="key">Key</Label>
            <Input
              id="token"
              type="text"
              value={token}
              disabled
              className="block mt-1 w-full p-2"
              required
              autoFocus
            />
          </div>
          
          {/* Email Address */}
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              value={email}
              className="block mt-1 w-full p-2"
              onChange={event => setEmail(event.target.value)}
              required
              autoFocus
            />
          </div>
          {/* Password */}
          <div className="mt-4">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={password}
              className="block mt-1 w-full p-2"
              onChange={event => setPassword(event.target.value)}
              required
            />
          </div>
          {/* Confirm Password */}
          <div className="mt-4">
            <Label htmlFor="password_confirmation">
                Confirm Password
            </Label>
            <Input
              id="password_confirmation"
              type="password"
              value={password_confirmation}
              className="block mt-1 w-full p-2"
              onChange={event =>
                setPasswordConfirmation(event.target.value)
              }
              required
            />
          </div>
          <div className="flex items-center justify-end mt-4">
            <Button>Reset Password</Button>
          </div>
        </form>
      </AuthCard>
    </GuestLayout>
  )
}

export default PasswordReset
