import { useAuth } from '../../hooks/auth'
import {useEffect} from 'react'
import GuestLayout from "../../components/dashboard/Layouts/GuestLayout";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";

const Logout = () => {
    const { logout } = useAuth();

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Logout"
        logout()
            .then(() => window.location.href = '/')
            .catch(()  => {
               // Do nothing
            });
    }, [logout]);

    return (
       <GuestLayout className="flex items-center justify-center min-h-screen h-screen">
           <div className="max-w-md w-full mx-auto flex items-center justify-center">
               <DashboardCard className="px-8 py-12">
                   <div className="flex flex-col items-center justify-center space-y-6">
                       <h2 className="text-xl font-bold">Logging out...</h2>
                       <p className="text-sm text-gray-500">Please wait a moment... We'll automatic redirect you to the homepage.</p>
                       <div className="w-16 h-16 border-t-2 border-b-2 border-blue-500 rounded-full animate-spin"></div>
                   </div>
               </DashboardCard>
           </div>
       </GuestLayout>
    )
}

export default Logout
