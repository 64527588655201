import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import AppLayout from '../../../components/dashboard/Layouts/AppLayout';
import axios from '../../../libs/axios';
import RoleEnum from '../../../components/enums/RoleEnum';
import { Shield, GraduationCap, User, UserPlus, UserCog } from 'lucide-react';
import RoleLabel from '../../../components/dashboard/RoleLabel';
import Pagination from '../../../components/dashboard/dashboard/Pagination';

const UserEdit = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const { user, csrf } = useAuth({ middleware: 'auth' });
    const [userData, setUserData] = useState(null);

    // Pagination states for tickets
    const [ticketCurrentPage, setTicketCurrentPage] = useState(1);
    const ticketsPerPage = 5;

    // Sorting states for tickets
    const [ticketSortField, setTicketSortField] = useState('status');
    const [ticketSortDirection, setTicketSortDirection] = useState('asc');

    useEffect(() => {
        const fetchUser = async () => {
            try {
                await csrf();
                
                const response = await axios.get(`/admin/user/${userId}`);
                setUserData(response.data);
            } catch (error) {
                navigate('/dashboard/admin');
            }
        };

        if (userId) {
            fetchUser();
        }
    }, [userId]);

    async function upgradeAccountToTakeTests(userID, roleID) {
        try {
            await csrf();
            const response = await axios.post(`/admin/upgrade-user-role/${userID}/${roleID}`);
            setUserData(prevState => ({
                ...prevState,
                ...response.data,
            }));
        } catch (error) {

         }
    }

    // Sorting and pagination calculations for tickets
    const sortedTickets = userData?.tickets ? [...userData.tickets].sort((a, b) => {
        const aValue = a[ticketSortField];
        const bValue = b[ticketSortField];
        
        // Primary sort by selected field
        let comparison;
        if (ticketSortField === 'created_at') {
            comparison = ticketSortDirection === 'asc' 
                ? new Date(aValue) - new Date(bValue)
                : new Date(bValue) - new Date(aValue);
        } else if (typeof aValue === 'number') {
            comparison = ticketSortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        } else {
            comparison = ticketSortDirection === 'asc'
                ? String(aValue).localeCompare(String(bValue))
                : String(bValue).localeCompare(String(aValue));
        }

        // If primary sort values are equal, sort by ID as secondary sort
        if (comparison === 0) {
            return a.id - b.id;  // Always sort IDs in ascending order as secondary sort
        }

        return comparison;
    }) : [];

    const indexOfLastTicket = ticketCurrentPage * ticketsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
    const currentTickets = sortedTickets.slice(indexOfFirstTicket, indexOfLastTicket);
    const totalTicketPages = Math.ceil(sortedTickets.length / ticketsPerPage);

    if (!userData) {
        return (
            <AppLayout>
                <div className="flex justify-center items-center min-h-screen">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
            </AppLayout>
        );
    }

    return (
        <AppLayout
            header={
                <h2 className="font-semibold text-xl dark:text-white leading-tight">
                    User Profile: {userData.name}
                </h2>
            }>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="bg-white dark:bg-gray-800 overflow-hidden shadow-sm sm:rounded-lg">
                        <div className="p-8">
                            {/* User Info Card */}
                            <div className="mb-8 bg-white dark:bg-gray-900 rounded-lg shadow-md">
                                <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-600">
                                    <h3 className="text-xl font-semibold text-gray-800 dark:text-white">User Information</h3>
                                </div>
                                <div className="p-6 grid grid-cols-1 md:grid-cols-3 gap-6">
                                    <div className="space-y-2">
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">ID</p>
                                        <p className="text-base font-semibold text-gray-900 dark:text-white">{userData.id}</p>
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Name</p>
                                        <p className="text-base font-semibold text-gray-900 dark:text-white">{userData.name}</p>
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Email</p>
                                        <p className="text-base font-semibold text-gray-900 dark:text-white">{userData.email}</p>
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Role</p>
                                        <RoleLabel role={userData.role} />
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Created At</p>
                                        <p className="text-base font-semibold text-gray-900 dark:text-white">
                                            {new Date(userData.created_at).toLocaleString()}
                                        </p>
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Status</p>
                                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                            ${userData.banned_user ? 
                                                'bg-red-100 text-red-800' : 
                                                'bg-green-100 text-green-800'}`}>
                                            {userData.banned_user ? 'Banned' : 'Active'}
                                        </span>
                                    </div>

                                    <div className="space-y-2">
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Profile ID</p>
                                        <a href={`${window.location.origin}/tools/profile/${userData.profile?.profile_id}`} className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                            ${userData.profile?.profile_id ? 
                                                'bg-green-100 text-green-800' : 
                                                'bg-red-100 text-red-800'}`}>
                                            {userData.profile?.profile_id ? userData.profile?.profile_id : 'none'}
                                        </a>
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Tools Visibility</p>
                                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                            ${userData.profile?.show_tool_profile ? 
                                                'bg-green-100 text-green-800' : 
                                                'bg-red-100 text-red-800'}`}>
                                            {userData.profile?.show_tool_profile ? 'Visible' : 'Disabled'}
                                        </span>
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Accepted Terms</p>
                                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                            ${userData.profile?.show_examples ? 
                                                'bg-green-100 text-green-800' : 
                                                'bg-red-100 text-red-800'}`}>
                                            {userData.profile?.show_examples ? 'Yes' : 'No'}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* Role Management Section */}
                            {user?.role >= RoleEnum.SuperAdmin && (
                                <div className="mb-8 bg-white dark:bg-gray-900 rounded-lg shadow-md">
                                    <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-600">
                                        <h3 className="text-xl font-semibold text-gray-800 dark:text-white">Role Management</h3>
                                    </div>
                                    <div className="p-6">
                                        <div className="flex space-x-4">
                                            <button
                                                onClick={() => upgradeAccountToTakeTests(userData.id, RoleEnum.Registered)}
                                                disabled={userData.banned_user}
                                                className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                                                    userData.banned_user || userData.role >= RoleEnum.Registered
                                                    ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-300 cursor-not-allowed'
                                                    : 'bg-green-100 dark:bg-green-800 text-green-700 dark:text-green-300 hover:bg-green-200 dark:hover:bg-green-700'
                                                }`}
                                            >
                                                <User className="w-5 h-5" />
                                                <span>Downgrade to Registered user</span>
                                            </button>

                                            <button
                                                onClick={() => upgradeAccountToTakeTests(userData.id, RoleEnum.DefaultUser)}
                                                disabled={userData.banned_user}
                                                className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                                                    userData.banned_user || userData.role >= RoleEnum.DefaultUser
                                                    ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-300 cursor-not-allowed'
                                                    : 'bg-blue-100 dark:bg-blue-800 text-blue-700 dark:text-blue-300 hover:bg-blue-200 dark:hover:bg-blue-700'
                                                }`}
                                            >
                                                <UserPlus className="w-5 h-5" />
                                                <span>Upgrade to DefaultUser</span>
                                            </button>

                                            <button
                                                onClick={() => upgradeAccountToTakeTests(userData.id, RoleEnum.Trainer)}
                                                disabled={userData.banned_user}
                                                className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                                                    userData.banned_user || userData.role >= RoleEnum.Trainer
                                                    ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-300 cursor-not-allowed'
                                                    : 'bg-orange-100 dark:bg-orange-800 text-orange-700 dark:text-orange-300 hover:bg-orange-200 dark:hover:bg-orange-700'
                                                }`}
                                            >
                                                <GraduationCap className="w-5 h-5" />
                                                <span>Upgrade to Trainer</span>
                                            </button>

                                            <button
                                                onClick={() => upgradeAccountToTakeTests(userData.id, RoleEnum.Moderator)}
                                                disabled={userData.banned_user}
                                                className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                                                    userData.banned_user || userData.role >= RoleEnum.Moderator
                                                    ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-300 cursor-not-allowed'
                                                    : 'bg-purple-100 dark:bg-purple-800 text-purple-700 dark:text-purple-300 hover:bg-purple-200 dark:hover:bg-purple-700'
                                                }`}
                                            >
                                                <UserCog className="w-5 h-5" />
                                                <span>Upgrade to Moderator</span>
                                            </button>

                                            <button
                                                onClick={() => upgradeAccountToTakeTests(userData.id, RoleEnum.Admin)}
                                                disabled={userData.banned_user}
                                                className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                                                    userData.banned_user || userData.role >= RoleEnum.Admin
                                                    ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-300 cursor-not-allowed'
                                                    : 'bg-red-100 dark:bg-red-800 text-red-700 dark:text-red-300 hover:bg-red-200 dark:hover:bg-red-700'
                                                }`}
                                            >
                                                <Shield className="w-5 h-5" />
                                                <span>Upgrade to Admin</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Add this after the User Info card and before the Tickets section */}
                            {userData.tests && userData.tests.length > 0 && (
                                <div className="mb-8 bg-white dark:bg-gray-900 rounded-lg shadow-md">
                                    <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-600">
                                        <h3 className="text-xl font-semibold text-gray-800 dark:text-white">Test Attempts</h3>
                                    </div>
                                    <div className="p-6 overflow-x-auto">
                                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                                            <thead className="bg-gray-50 dark:bg-gray-600">
                                                <tr>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Test ID
                                                    </th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Score
                                                    </th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Answers
                                                    </th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Finished At
                                                    </th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Certificate
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white dark:bg-gray-700 divide-y divide-gray-200 dark:divide-gray-600">
                                                {userData.tests.map(test => (
                                                    <tr key={test.id} className="hover:bg-gray-50 dark:hover:bg-gray-600">
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                                                            {test.test_id}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                                                ${parseFloat(test.score) >= 85 
                                                                    ? 'bg-green-100 dark:bg-green-700 text-green-800 dark:text-green-300'
                                                                    : 'bg-red-100 dark:bg-red-700 text-red-800 dark:text-red-300'}`}>
                                                                {test.score}%
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                                                            <span className="text-green-600 dark:text-green-300">{test.correct_answers} correct</span>
                                                            {test.wrong_answers > 0 && (
                                                                <span className="text-red-600 dark:text-red-300 ml-2">{test.wrong_answers} wrong</span>
                                                            )}
                                                            {test.not_filled_in > 0 && (
                                                                <span className="text-gray-500 dark:text-gray-400 ml-2">{test.not_filled_in} skipped</span>
                                                            )}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                                                            {test.finished_at ? new Date(test.finished_at).toLocaleString() : 'In Progress'}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                                                            {test.certificate_id ? (
                                                                <a
                                                                    href={`/certificate/show/${test.certificate_id}`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="text-blue-600 hover:text-blue-800 flex items-center space-x-1"
                                                                >
                                                                    <span>{test.certificate_id}</span>
                                                                    <span className="text-xs">{new Date(test.certificate_date).toLocaleDateString()}</span>
                                                                </a>
                                                            ) : (
                                                                'Not Available'
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                            
                            {/* Tickets Section */}
                            {userData.tickets && userData.tickets.length > 0 && (
                                <div className="bg-white dark:bg-gray-900 rounded-lg shadow-md mt-8">
                                    <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-600">
                                        <h3 className="text-xl font-semibold text-gray-800 dark:text-white">Support Tickets</h3>
                                    </div>
                                    <div className="p-6 overflow-x-auto">
                                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                                            <thead className="bg-gray-50 dark:bg-gray-700">
                                                <tr>
                                                    {[
                                                        { id: 'id', label: 'ID' },
                                                        { id: 'category', label: 'Category' },
                                                        { id: 'status', label: 'Status' },
                                                        { id: 'description', label: 'Description' },
                                                        { id: 'created_at', label: 'Created At' }
                                                    ].map(column => (
                                                        <th 
                                                            key={column.id}
                                                            onClick={() => {
                                                                if (ticketSortField === column.id) {
                                                                    setTicketSortDirection(current => current === 'asc' ? 'desc' : 'asc');
                                                                } else {
                                                                    setTicketSortField(column.id);
                                                                    setTicketSortDirection('asc');
                                                                }
                                                            }}
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                                                        >
                                                            <div className="flex items-center space-x-1">
                                                                <span className="text-gray-800 dark:text-white">{column.label}</span>
                                                                {ticketSortField === column.id && (
                                                                    <span className="text-gray-400">
                                                                        {ticketSortDirection === 'asc' ? ' ↑' : ' ↓'}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-600">
                                                {currentTickets.map(ticket => (
                                                    <tr key={ticket.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                                                            {ticket.id}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                                            {ticket.category}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                                                ${ticket.status === 0 ? 'bg-yellow-100 text-yellow-800' : 
                                                                  ticket.status === 1 ? 'bg-green-100 text-green-800' : 
                                                                  'bg-red-100 text-red-800'}`}>
                                                                {ticket.status === 0 ? 'Pending' : 
                                                                 ticket.status === 1 ? 'Open' : 
                                                                 'Closed'}
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
                                                            <div className="max-w-xs truncate">
                                                                {ticket.description}
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                                            {new Date(ticket.created_at).toLocaleString()}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        {/* Pagination */}
                                        <div className="mt-4">
                                            <Pagination 
                                                currentPage={ticketCurrentPage}
                                                totalPages={totalTicketPages}
                                                onPageChange={setTicketCurrentPage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Back Button */}
                            <div className="mt-6 flex justify-end">
                                <button
                                    onClick={() => navigate('/dashboard/admin')}
                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:bg-gray-800 dark:hover:bg-gray-900"
                                >
                                    Back to Dashboard
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default UserEdit; 