export type CategoryType = "All" | "Text" | "Images" | "Video" | "Music" | "Maps" | "Podcasts" | "Code" | "Visuals" | "Automation" | "Avatar" | "Search" | "Computer Use" | "Voice" | "Prompt Library" | "Transcription" | "Audio" | "IDE Integration" | "Vision" | "Presentations";

// Create a constant array from the type (excluding "All")
export const CATEGORIES = {
  "All": { name: "All", description: "All categories combined" },
  "Text": { name: "Text", description: "Generate textual content" },
  "Images": { name: "Images", description: "Generate images" },
  "Video": { name: "Video", description: "Generate videos" },
  "Audio": { name: "Audio", description: "Generate audio" },
  "Music": { name: "Music", description: "Generate music" },
  "Podcasts": { name: "Podcasts", description: "Generate podcasts" },
  "Code": { name: "Code", description: "Generate code" },
  "IDE Integration": { name: "IDE Integration", description: "Integrated with IDEs or has it's own IDE" },
  "Visuals": { name: "Visuals", description: "Generate visuals" },
  "Automation": { name: "Automation", description: "Automate tasks" },
  "Avatar": { name: "Avatar", description: "Generate avatars" },
  "Search": { name: "Search", description: "Search the internet" },
  "Maps": { name: "Maps", description: "Generate maps from your data" },
  "Presentations": { name: "Presentations", description: "Generate presentations" },
  "Computer Use": { name: "Computer Use", description: "Prompt the tool to take control of your computer" },
  "Voice": { name: "Voice", description: "Don't write your prompts, but speak to it" },
  "Prompt Library": { name: "Prompt Library", description: "Store and manage your prompts" },
  "Transcription": { name: "Transcription", description: "Generate transcriptions" },
  "Vision": { name: "Vision", description: "Use your camera to add context to the conversation" },
} as const;

interface CategoryBadgeProps {
  category: string;
}

export const CategoryBadge = ({ category }: CategoryBadgeProps) => (
  <span
    className={`px-2 py-1 text-xs font-semibold rounded-full
      ${category === "Text" ? "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200" :
      category === "Images" ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200" :
      category === "Video" ? "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200" :
      category === "Music" ? "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200" :
      category === "Code" ? "bg-amber-100 text-amber-800 dark:bg-amber-900 dark:text-amber-200" :
      category === "Visuals" ? "bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-200" :
      category === "Automation" ? "bg-cyan-100 text-cyan-800 dark:bg-cyan-900 dark:text-cyan-200" :
      category === "Avatar" ? "bg-emerald-100 text-emerald-800 dark:bg-emerald-900 dark:text-emerald-200" :
      category === "Search" ? "bg-fuchsia-100 text-fuchsia-800 dark:bg-fuchsia-900 dark:text-fuchsia-200" :
      category === "Computer Use" ? "bg-rose-100 text-rose-800 dark:bg-rose-900 dark:text-rose-200" :
      category === "Voice" ? "bg-violet-100 text-violet-800 dark:bg-violet-900 dark:text-violet-200" :
      category === "Prompt Library" ? "bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200" :
      category === "Transcription" ? "bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200" :
      category === "Maps" ? "bg-sky-100 text-sky-800 dark:bg-sky-900 dark:text-sky-200" :
      category === "Audio" ? "bg-teal-100 text-teal-800 dark:bg-teal-900 dark:text-teal-200" :
      category === "IDE Integration" ? "bg-lime-100 text-lime-800 dark:bg-lime-900 dark:text-lime-200" :
      category === "Vision" ? "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200" :
      category === "Presentations" ? "bg-teal-100 text-teal-800 dark:bg-teal-900 dark:text-teal-200" :
      category === "All" ? "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200" :
      "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200"}`}
  >
    {category}
  </span>
);