import React, { useState } from 'react';
import { Building, Users, Brain, CircleDot, ChevronRight, AlertCircle, ArrowLeft, Info, MessageSquare } from 'lucide-react';

const AILiteracyGuide = () => {
  const [step, setStep] = useState(1);
  const [selections, setSelections] = useState({
    organizationTypes: [],
    aiUsage: [],
  });
  const [showDeployerInfo, setShowDeployerInfo] = useState(false);
  const [step1Deployer, setStep1Deployer] = useState(false);

  const organizationTypes = [
    { 
      id: 'provider', 
      label: 'AI Provider', 
      icon: CircleDot, 
      description: 'We develop our own AI systems that we provide to others' 
    },
    { 
      id: 'deployer', 
      label: 'AI Deployer', 
      icon: Building, 
      description: 'We implement AI systems in our operations (including using third-party AI services for customers, like chatbots)' 
    },
    { 
      id: 'user', 
      label: 'AI User', 
      icon: Users, 
      description: 'We only use AI tools internally for our own work (no customer-facing AI)' 
    }
  ];

  const aiUsageTypes = [
    { 
      id: 'customer-service', 
      label: 'Customer Service AI', 
      icon: MessageSquare,
      description: 'Chatbots, virtual assistants, or AI systems that interact with customers',
      makeDeployer: true
    },
    { 
      id: 'data-analysis', 
      label: 'Data Analysis & Business Intelligence', 
      icon: Brain,
      description: 'AI systems for analyzing business data, predictive analytics, or pattern recognition',
      makeDeployer: false
    },
    { 
      id: 'content-generation', 
      label: 'Content Generation', 
      icon: CircleDot,
      description: 'AI tools for creating text, images, or other content (e.g., GPT, DALL-E)',
      makeDeployer: false
    },
    { 
      id: 'process-automation', 
      label: 'Process Automation', 
      icon: CircleDot,
      description: 'AI systems that automate workflows, document processing, or business processes',
      makeDeployer: false
    },
    { 
      id: 'decision-support', 
      label: 'Decision Support Systems', 
      icon: Brain,
      description: 'AI tools that help in making business decisions or risk assessments',
      makeDeployer: false
    },
    { 
      id: 'computer-vision', 
      label: 'Computer Vision Systems', 
      icon: CircleDot,
      description: 'AI for image/video analysis, quality control, or visual inspections',
      makeDeployer: true
    }
  ];

  const handleOrganizationSelect = (type) => {
    const newTypes = selections.organizationTypes.includes(type)
      ? selections.organizationTypes.filter(t => t !== type)
      : [...selections.organizationTypes, type];
    setSelections({ ...selections, organizationTypes: newTypes });

    // Check if any selected organization types make the organization a deployer
    const isDeployer = organizationTypes
      .filter(type => [...newTypes].includes(type.id))
      .some(type => type.id === 'deployer');
    
    if (isDeployer) {
      setStep1Deployer(true);
    } else {
      setStep1Deployer(false);
    }
  };

const handleUsageSelect = (usage) => {
    const newUsage = selections.aiUsage.includes(usage)
      ? selections.aiUsage.filter(u => u !== usage)
      : [...selections.aiUsage, usage];
    
    // Check if any selected usage types make the organization a deployer
    const isDeployer = aiUsageTypes
      .filter(type => [...newUsage].includes(type.id))
      .some(type => type.makeDeployer);
    
    if (!step1Deployer && isDeployer) {
      // If it qualifies as a deployer and was not previously a deployer, set it
      setSelections({
        ...selections,
        aiUsage: newUsage,
        organizationTypes: [...new Set([...selections.organizationTypes, 'deployer'])]
      });
      setShowDeployerInfo(true);
    } else {
      // If it was already a deployer, just update the usage
      setSelections({ ...selections, aiUsage: newUsage });
      setShowDeployerInfo(false);

      if (!step1Deployer) {
        setSelections({
          ...selections,
          aiUsage: newUsage,
          organizationTypes: selections.organizationTypes.filter(type => type !== 'deployer')
        });
      }
    }
};

  const getRecommendation = () => {
    const isDeployer = selections.organizationTypes.includes('deployer');
    const hasCustomerFacing = selections.aiUsage.includes('customer-service');

    let recommendations = [];

    if (selections.organizationTypes.includes('provider') || isDeployer) {
      recommendations.push({
        required: true,
        message: 'Based on AI governance principles, organizations that provide or deploy AI systems need to ensure proper training for their employees.',
        icon: AlertCircle,
        color: 'text-gray-900'
      });
    } else {
      recommendations.push({
        required: false,
        message: 'While not typically mandatory, it is recommended to provide AI literacy training to ensure responsible and effective use of AI tools.',
        icon: Info,
        color: 'text-gray-600'
      });
    }

    if (hasCustomerFacing) {
      recommendations.push({
        required: true,
        message: 'According to Article 50(1) of the AI Act, you must inform users when they are interacting with an AI system, unless this is obvious. For chatbots and similar systems, you must clearly indicate that customers are talking to an AI system.',
        icon: AlertCircle,
        color: 'text-gray-900'
      });
    }

    return recommendations;
  };

  const resetStep = (step) => {
    if (step === 2) {
      if (!step1Deployer) {
        setSelections({
          ...selections,
          aiUsage: [],
          organizationTypes: selections.organizationTypes.filter(type => type !== 'deployer')
        });
        setShowDeployerInfo(false);
      }
      else {
        setSelections({
          ...selections,
          aiUsage: [],
        });
      }
    }
  };

  const [closeImportantNotice, setCloseImportantNotice] = useState(false);

  return (
    <div className="max-w-4xl mx-auto p-6">
      {!closeImportantNotice && (
      <div className="mb-4 p-4 bg-orange-100 dark:bg-orange-700 border border-orange-200 dark:border-orange-500 rounded-lg">
        <div className="flex items-start gap-3 justify-between">
          <AlertCircle className="w-6 h-6 text-orange-800 flex-shrink-0 mt-1 dark:text-orange-200" />
          <div>
            <p className="font-semibold text-orange-800 dark:text-white">Important Notice</p>
            <p className="text-gray-600 dark:text-white">This assessment was generated by NotebookLM, Claude, Perplexity and Windsurf based on the input from the full documentation of the AI Act. Please be aware that the output of these models is not fully covered by legal guidance and should not be used as a substitute for legal advice. However, the output might give you a good idea of what to think about when using customer-facing AI systems.</p>
          </div>
          <button onClick={() => setCloseImportantNotice(true)} className="hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full p-1">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600 dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>)}

      {showDeployerInfo && (
        <div className="mb-4 p-4 bg-gray-100 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg">
          <div className="flex items-start gap-3">
            <Info className="w-6 h-6 text-gray-600 flex-shrink-0 mt-1 dark:text-gray-300" />
            <div>
              <p className="font-semibold text-gray-800 dark:text-white">You're an AI Deployer</p>
              <p className="text-gray-600 dark:text-gray-400">We've automatically selected "AI Deployer" as your organization type because you're using customer-facing AI systems. This means you have specific obligations regarding training and transparency.</p>
            </div>
          </div>
        </div>
      )}

      <div className="mb-8">
        <div className="text-2xl font-bold text-center bg-gradient-to-r from-blue-600 to-purple-600 text-white p-6 rounded-t-lg dark:bg-gray-800 dark:text-gray-300">
          AI Literacy Assessment Guide
        </div>
        <div className="p-6">
          {/* Navigation */}
          <div className="flex justify-between items-center mb-6">
            {step > 1 && (
              <button 
                onClick={() => { resetStep(step); setStep(step - 1); }}
                className="flex items-center text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-white"
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                Back
              </button>
            )}
            <div className="text-sm text-gray-600 dark:text-gray-400">
              Step {step} of 2
            </div>
          </div>

          {step === 1 && (
            <div>
              <h2 className="text-xl font-semibold mb-2 dark:text-gray-300">What describes your organization?</h2>
              <p className="text-gray-600 dark:text-gray-400 mb-6">Select all that apply to your organization</p>
              <div className="grid gap-4 md:grid-cols-3">
                {organizationTypes.map(({ id, label, icon: Icon, description }) => (
                  <button
                    key={id}
                    onClick={() => handleOrganizationSelect(id)}
                    className={`p-6 rounded-lg border-2 transition-all hover:shadow-lg ${
                      selections.organizationTypes.includes(id)
                        ? 'border-blue-500 bg-blue-50 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                        : 'border-gray-200 hover:border-gray-300 dark:border-gray-700 dark:hover:border-gray-600'
                    }`}
                    style={{
                      borderColor: selections.organizationTypes.includes(id) ? 'rgba(96, 165, 250, 0.5)' : undefined
                    }}
                  >
                    <Icon className="w-12 h-12 mb-4 mx-auto text-blue-500 dark:text-gray-300" />
                    <h3 className="font-semibold mb-2 dark:text-gray-300">{label}</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">{description}</p>
                  </button>
                ))}
              </div>
              {selections.organizationTypes.length > 0 && (
                <button
                  onClick={() => setStep(2)}
                  className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center dark:bg-gray-800 dark:text-gray-300"
                >
                  Continue
                  <ChevronRight className="w-4 h-4 ml-2" />
                </button>
              )}
            </div>
          )}

          {step === 2 && (
            <div>
              <h2 className="text-xl font-semibold mb-2 dark:text-gray-300">What types of AI does your organization use?</h2>
              <p className="text-gray-600 dark:text-gray-400 mb-6">Select all AI technologies you currently use or plan to use</p>
              <div className="grid gap-4 md:grid-cols-2 mb-6">
                {aiUsageTypes.map(({ id, label, icon: Icon, description, makeDeployer }) => (
                  <button
                    key={id}
                    onClick={() => handleUsageSelect(id)}
                    className={`p-6 rounded-lg border-2 transition-all hover:shadow-lg ${
                      selections.aiUsage.includes(id)
                        ? 'border-blue-500 bg-blue-50 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                        : 'border-gray-200 hover:border-gray-300 dark:border-gray-700 dark:hover:border-gray-600'
                    }`}
                    style={{
                      borderColor: selections.aiUsage.includes(id) ? 'rgba(96, 165, 250, 0.5)' : undefined
                    }}
                  >
                    <div className="flex items-start">
                      <Icon className="w-8 h-8 mr-4 text-blue-500 flex-shrink-0 dark:text-gray-300" />
                      <div className="text-left">
                        <h3 className="font-semibold mb-2 dark:text-gray-300">{label}</h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400">{description}</p>
                        {makeDeployer && !step1Deployer && (
                          <div className="mt-2 bg-yellow-100 dark:bg-yellow-900 rounded p-2 flex items-center space-x-2 border border-yellow-500 dark:border-yellow-700">
                            <AlertCircle className="w-5 h-5 text-yellow-600 dark:text-yellow-400" />
                            <p className="text-sm mb-0 font-semibold text-yellow-600 dark:text-yellow-400">
                              Note: This will make you an AI Deployer
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </button>
                ))}
              </div>
              {selections.aiUsage.length > 0 && (
                <div className="mt-8 p-6 rounded-lg bg-gray-50 dark:bg-gray-800">
                  <h3 className="text-xl font-semibold mb-4 dark:text-gray-300">Your Assessment Result</h3>
                  <div className="space-y-6">
                    {getRecommendation().map((recommendation, index) => {
                      const Icon = recommendation.icon;
                      return (
                        <div key={index} className={`flex items-start gap-4 ${recommendation.color}`}>
                          <Icon className="w-6 h-6 mt-1 flex-shrink-0 dark:text-gray-300" />
                          <div>
                            <p className={`font-semibold mb-2 ${recommendation.required ? 'text-red-600' : 'text-yellow-600'}`}>
                              {recommendation.required ? 'Required Action' : 'Recommendation'}
                            </p>
                            <p className="text-gray-600 dark:text-gray-400">{recommendation.message}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AILiteracyGuide;
