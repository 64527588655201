import React, {useEffect, useRef} from "react";
// Layouts
import DefaultLayout from "../layouts/default";
// Components
import ThreePhasesSection from "../components/sections/ThreePhases";
import BackToTop from "../components/base/BackToTop";
import HeaderBanner from "../components/sections/HeaderBanner";
import {useLocation} from "react-router-dom";

function ContentPolicy() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Content usage policy"
  }, []);

  const location = useLocation();
  const lastHash = useRef('');

  useEffect(() => {
    goToLocation(-130);
  });

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    goToLocation(-20);
  });

  function goToLocation(yOffset: number) {

    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        const element = document.getElementById(lastHash.current);
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }

        lastHash.current = '';
      }, 50);
    }
  }

  return (
    <div className="Blogs dark:bg-slate-900 dark:text-white">
      <DefaultLayout>
        <HeaderBanner title="Content Policy"  />

        <section className="relative flex max-w-full sm:mx-6 shadow sm:rounded-2xl overflow-hidden dark:bg-slate-900 dark:text-white">
          <div className="w-full px-6 sm:px-0 py-16 flex flex-col items-center justify-center space-y-4 dark:text-white"
          data-aos="fade-up">
            <div className={"max-w-6xl mx-8"} id="content-policy">
              <h3>
                Content Usage <span className="text-header-gradient">Policy</span>
              </h3>
              <p className="text-sm text-gray-600 mb-8">Effective Date: August 1st, 2024</p>

              <p className=" mb-12">
                This Content Usage Policy outlines the terms under which you may use the materials available on <span className="font-semibold">Crafting AI Prompts Framework</span>, accessible at <a href="https://craftingaiprompts.org" className="text-blue-600 hover:underline">craftingaiprompts.org</a> (referred to as "the Website"). By using the content from the Website, you agree to comply with the following terms and conditions:
              </p>

              <h2 className="text-2xl font-semibold mb-4">1. Permissible Use</h2>
              <ul className="list-disc list-inside mb-12">
                <li className="mb-3">
                  <span className="font-semibold">Presentations and Educational Materials:</span> You may include content in presentations or educational materials (in compliance with section 2), provided that visible credit is given to <a href="https://craftingaiprompts.org" className="text-blue-600 hover:underline">craftingaiprompts.org</a> in your slides or handouts, and that the "Crafting AI Prompts Framework" is mentioned in the abstract.
                </li>
                <li className="mb-3">
                  <span className="font-semibold">Internal or External Training Sessions:</span> If you wish to use the content in your internal or external training sessions (paid or unpaid), you must include a reference to the Website. Additionally, if applicable, the course outline available online for registration should indicate that the Crafting AI Prompts Framework is being used.
                </li>
                <li className="mb-3">
                  <span className="font-semibold">Articles, Blog Posts, and Other Written Materials:</span> You may reference, discuss, or adapt the content in your own articles, blog posts, or similar written materials. However, you must include a clear and visible reference to <a href="https://craftingaiprompts.org" className="text-blue-600 hover:underline">craftingaiprompts.org</a>, including a hyperlink where applicable.
                </li>
                <li className="mb-3">
                  <span className="font-semibold">Adaptations and Derivative Works:</span> You may adapt the content to suit your presentation style or audience needs, but a visible reference to <a href="https://craftingaiprompts.org" className="text-blue-600 hover:underline">craftingaiprompts.org</a> is mandatory.
                </li>
              </ul>

              <h2 className="text-2xl font-semibold mb-4">2. Prohibited Use</h2>
              <ul className="list-disc list-inside mb-6">
                <li className="mb-3">
                  <span className="font-semibold">Copying Verbatim:</span> You may not copy the complete content verbatim from the Website. Instead, you should interpret the information in your own way and present it in a manner that best serves your audience while always providing appropriate credit.
                </li>
                <li className="mb-3">
                  <span className="font-semibold">Paywall Restrictions:</span> The content from the Website, including any references or links to <a href="https://craftingaiprompts.org" className="text-blue-600 hover:underline">craftingaiprompts.org</a>, must be visible to everyone and may not be placed behind a paywall. It is permitted to discuss the Crafting AI Prompts Framework in paid educational materials; however, the course outline or description, which is visible before purchase, must clearly indicate that the Crafting AI Prompts Framework is used. Additionally, we reserve the right to request a review of the content to ensure compliance with this policy.
                </li>
                <li className="mb-3">
                  <span className="font-semibold">Misrepresentation:</span> You may not claim the content as your own or misrepresent its origin. Additionally, you may not register or use a domain name, social media handle, or any other online identifier that could be reasonably confused with the official Website domain name, <a href="https://craftingaiprompts.org" className="text-blue-600 hover:underline">craftingaiprompts.org</a>, or any other property of the Website. Furthermore, you may not create content, advertising, or promotions that give the impression that your work is the official or primary source of information about the Crafting AI Prompts Framework.
                </li>
                <li className="mb-3">
                  <span className="font-semibold">Certification Restrictions:</span> You may not create or offer any certification program related to the Crafting AI Prompts Framework or its content. An official certification program is available exclusively on this Website. However, creating supplementary content to assist individuals in preparing for the official assessment is permitted, provided it adheres strictly to the <a href={"/test/terms#test-terms"} className="text-blue-600 hover:underline">Test - Terms</a>.
                </li>
              </ul>
              <p className="mb-12">
                The content from this Website is intended to be freely accessible to everyone, reflecting the mission of sharing knowledge so that all can benefit. As such, any use of the content must ensure that it remains available to the public without restrictions, allowing everyone the opportunity to take advantage of this valuable resource.
              </p>

              <h2 className="text-2xl font-semibold mb-4">3. Attribution Requirements</h2>
              <p className=" mb-6">
                All uses of the Website’s content must include a clear and visible reference to <a href="https://craftingaiprompts.org" className="text-blue-600 hover:underline">craftingaiprompts.org</a>. This includes:
              </p>
              <ul className="list-disc list-inside mb-12">
                <li className="mb-3">
                  <span className="font-semibold">Hyperlinks:</span> in online content, such as blog posts or digital articles.
                </li>
                <li className="mb-3">
                  <span className="font-semibold">Visible Credits:</span> in physical or digital presentations, educational materials, and any other forms of media.
                </li>
              </ul>

              <h2 className="text-2xl font-semibold mb-4">4. Special Permissions</h2>
              <p className=" mb-12">
                If you wish to use the content in a way that is not covered by this policy, or if you have any questions about its application, please contact <span className="font-semibold"><a href={"https://www.linkedin.com/in/jegelmeers/"} target={"_blank"} className="text-blue-600 hover:underline" title={"Jeroen Egelmeers - LinkedIn"}>Jeroen Egelmeers</a></span>. I am open to discussing special permissions or use cases that may not fit within these guidelines.
              </p>

              <h2 className="text-2xl font-semibold mb-4">5. Reporting Misuse</h2>
              <p className=" mb-12">
                If you encounter content from the Website being used without proper attribution or being placed behind a paywall, please report it to <span className="font-semibold"><a href={"https://www.linkedin.com/in/jegelmeers/"} target={"_blank"} className="text-blue-600 hover:underline" title={"Jeroen Egelmeers - LinkedIn"}>Jeroen Egelmeers</a></span>. I appreciate your help in ensuring that our work is respected and properly credited.
              </p>

              <h2 className="text-2xl font-semibold mb-4">6. Changes to This Policy</h2>
              <p className=" mb-12">
                We reserve the right to modify or update this Content Usage Policy at any time. Any changes will be posted on this page, with the updated effective date at the top. Users will have 30 days from the effective date to comply with the new terms.
              </p>

              <p className=" font-semibold">
                Thank you for respecting this work and ensuring that proper attribution is given. By doing so, you help support our mission to share knowledge widely and freely.
              </p>
            </div>
          </div>
        </section>

        <ThreePhasesSection />

        <BackToTop sticky={false} />
      </DefaultLayout>
    </div>
  );
}

export default ContentPolicy;
