import React from "react";
import {ShieldCheck, GraduationCap} from 'lucide-react';
import RoleEnum from "../../../components/enums/RoleEnum";

const TicketUpdate = ({ update }) => {
    return (
        <div className={`mb-4 rounded-lg overflow-hidden
            ${update.user.role >= RoleEnum.Admin 
                ? 'bg-purple-50 border border-purple-100 dark:bg-purple-900 dark:border-purple-700' 
                : update.user.role === RoleEnum.Trainer 
                    ? 'bg-red-50 border border-red-100 dark:bg-red-900 dark:border-red-700' 
                    : 'bg-blue-50 border border-blue-100 dark:bg-blue-900 dark:border-blue-700'
            }`}
        >
            {/* Header with user info */}
            <div className="px-4 py-3 border-b border-opacity-20 flex items-center justify-between">
                <div className="flex items-center gap-2">
                    {/* User initial avatar */}
                    <div className={`w-8 h-8 rounded-full flex items-center justify-center
                        ${update.user.role >= RoleEnum.Admin 
                            ? 'bg-purple-100 dark:bg-purple-800' 
                            : update.user.role === RoleEnum.Trainer
                                ? 'bg-red-100 dark:bg-red-800' 
                                : 'bg-blue-100 dark:bg-blue-800'
                        }`}
                    >
                        <span className="text-sm font-semibold">
                            {update.user.name[0].toUpperCase()}
                        </span>
                    </div>

                    {/* Username and role icon */}
                    <div className="flex items-center gap-2">
                        <span className="font-medium text-gray-900 dark:text-gray-100">
                            {update.user.name}
                        </span>
                        {update.user.role >= RoleEnum.Admin ? (
                            <ShieldCheck className="text-purple-500 dark:text-purple-300" size={16} />
                        ) : update.user.role === RoleEnum.Trainer ? (
                            <GraduationCap className="text-red-500 dark:text-red-300" size={16} />
                        ) : null}
                    </div>
                </div>

                {/* Timestamp */}
                <span className="text-xs text-gray-500 dark:text-gray-400">
                    {new Date(update.updated_at).toLocaleString('en-US', { 
                        month: 'long', 
                        day: '2-digit', 
                        year: 'numeric', 
                        hour: '2-digit', 
                        minute: '2-digit', 
                        hour12: false 
                    })}
                </span>
            </div>

            {/* Message content */}
            <div className="p-4">
                <p className="text-gray-700 dark:text-gray-300">
                    {update.description}
                </p>
            </div>
        </div>
    );
};

export default TicketUpdate;