import useSWR from 'swr'
import axios from '../libs/axios'
import {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import RoleEnum from "../components/enums/RoleEnum";

export const useAuth = ({middleware = 'guest', redirectIfAuthenticated = false} = {}) => {
    let navigate = useNavigate();
    let params = useParams();

    const {data: user, error, mutate} = useSWR('/user', () =>
            axios
                .get('/user')
                .then(res => {
                    const userData = res.data;
                    if (userData.profile && userData.profile.show_examples) {
                        localStorage.setItem('safetyTermsInjections', 'true');
                    }else {
                        localStorage.removeItem('safetyTermsInjections');
                    }

                    return userData;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        if (localStorage.getItem('username') !== null && localStorage.getItem('username') !== "") {
                            localStorage.setItem('username', "");
                            localStorage.setItem('open_tickets', 0);
                        }
                        if (redirectIfAuthenticated && window.location.pathname !== "/login" && window.location.pathname !== "/register" && window.location.pathname !== "/forgot-password" && window.location.pathname !== "/reset-password") {
                            logout();
                        }
                        return null;
                    }
                    if (error.response.status !== 409) {
                        //throw error // Put off to remove logging to console.
                        //mutate('/verify-email')
                    }
                }),
        {
            revalidateIfStale: true,
            revalidateOnFocus: true,
        }
    )

    const csrf = () => axios.get('/sanctum/csrf-cookie')

    const register = async ({setErrors, ...props}) => {
        await csrf()
        setErrors([])
        axios
            .post('/register', props)
            .then(() => mutate().then(() => {
                navigate('/dashboard/overview');  // Redirect to index page
            }))
            .catch(error => {
                //if (error.response.status !== 422) throw error
                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const login = async ({setErrors, setStatus, ...props}) => {
        await csrf()
        setErrors([])
        setStatus(null)
        axios
            .post('/login', props)
            .then(() => {
                window.location.reload(); // Reload so the page post is gone
                mutate();
                
            })
            .catch(error => {
                //if (error.response.status !== 422) throw error
                if (error.response.data.errors) {
                    setErrors(Object.values(error.response.data.errors).flat())
                }else if(error.response.data.message) {
                    setErrors(Object.values([error.response.data.message]).flat());
                }else {
                    setErrors(Object.values(["Unknown error"]).flat());
                }
            });

    }

    const forgotPassword = async ({setErrors, setStatus, email}) => {
        await csrf()
        setErrors([])
        setStatus(null)
        axios
            .post('/forgot-password', {email})
            .then(response => setStatus(response.data.status))
            .catch(error => {
                //if (error.response.status !== 422) throw error
                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resetPassword = async ({setErrors, setStatus, ...props}) => {
        await csrf()
        setErrors([])
        setStatus(null)
        axios
            .post('/reset-password', {token: params.token, ...props})
            .then(response => navigate(`/login?reset=${  btoa(response.data.status)}`))
            .catch(error => {
                //if (error.response.status !== 422) throw error
                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resendEmailVerification = ({setStatus}) => {
        axios
            .post('/email/verification-notification')
            .then(response => setStatus(response.data.status))
    }

    const logout = async () => {
        if (!error) {
            await axios.post('/logout')
                .then(() => {
                    mutate();
                })
                .catch(() => { /* Do nothing */ });
        }

        localStorage.removeItem('username');
        localStorage.removeItem('safetyTermsInjections');

        if (window.location.pathname !== '/login') {
            window.location.pathname = '/login'
        }
    }

    useEffect(() => {
        if (middleware === 'guest' && redirectIfAuthenticated && user) navigate(redirectIfAuthenticated)
        if (middleware === 'auth' && error) logout()
        if (middleware === 'trainer') {
            if (user && user.role < RoleEnum.Trainer) {
                navigate('/dashboard')
            }else if(error){
                logout();
            }
        }
        if (middleware === 'moderator') {
            if (user && user.role < RoleEnum.Moderator) {
                navigate('/dashboard')
            }else if(error){
                logout();
            }
        }
        if (middleware === 'admin' && !error) {
            if (user && user.role < RoleEnum.Admin) {
                navigate('/dashboard')
            }
        }

        if (user) {
            localStorage.setItem('username', user.name);
            if (user.open_tickets !== undefined) {
                localStorage.setItem('open_tickets', user.open_tickets);
            }
            if (user.total_open_tickets !== undefined) {
                localStorage.setItem('total_open_tickets', user.total_open_tickets);
            }
            if (user.total_pending_tickets !== undefined) {
                localStorage.setItem('total_pending_tickets', user.total_pending_tickets);
            }
        }else{
            localStorage.setItem('username', "");
            localStorage.setItem('open_tickets', 0);
            localStorage.setItem('total_open_tickets', 0);
            localStorage.setItem('total_pending_tickets', 0);
        }
    }, [user, error])

    return {
        user,
        register,
        login,
        forgotPassword,
        resetPassword,
        resendEmailVerification,
        logout,
        csrf
    }
}