// Components
import React, {useEffect, useState} from "react";
import DocumentationNav from "./DocumentationNav";
import QuadrantChart from "../../components/documentation/QuadrantChart";
import axios from "../../libs/axios";
import { useAuth } from "../../hooks/auth";

function CodingFrameworkDocumentation() {
  const { user } = useAuth({ middleware: 'admin' })

  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Coding Assistants"
  }, []);

  enum XLabel {
    TEXT = "Textual output",
    RUN = "Run",
    PUBLISH = "Publish",
    DEPLOY = "Deploy",
    IDEINT = "IDE Integration",
  }

  enum YLabel {
    CODE_COMPLETION = "Code compl.",
    TASK_AUTOMATION = "Task autom.",
    PROJECT_AUTOMATION = "Project autom.",
    AI_ENGINEER = "AI Engineer",
    AIDevTeams = "AI Dev teams",
  }

  const xLabels: XLabel[] = [XLabel.TEXT, XLabel.RUN, XLabel.PUBLISH, XLabel.DEPLOY, XLabel.IDEINT];
  const yLabels: YLabel[] = [
    YLabel.CODE_COMPLETION,
    YLabel.TASK_AUTOMATION,
    YLabel.PROJECT_AUTOMATION,
    YLabel.AI_ENGINEER,
  ];

  const [dataPoints, setDataPoints] = useState<{ xLabel: XLabel; yLabel: YLabel; xRating: number; yRating: number; label: string; url?: string }[]>([]);
  const [quadrantError, setQuadrantError] = useState<string | null>(null);
  const [filterType, setFilterType] = useState<'rated' | 'unrated' | null>(null);

  useEffect(() => {

    const fetchData = async () => {
      try {
          const response = await axios.get(`/tools/code/quadrant`);
          if (response.data.success) {
              // Map the API response to match our XLabel and YLabel enums
              const mappedData = response.data.data.map((point: any) => ({
                  xLabel: point.xLabel === 'IDE Integration' ? XLabel.IDEINT : point.xLabel === 'Run' ? XLabel.RUN : point.xLabel === 'Publish' ? XLabel.PUBLISH : point.xLabel === 'Deploy' ? XLabel.DEPLOY : XLabel.TEXT,
                  yLabel: point.yLabel === 'AI Engineer' ? YLabel.AI_ENGINEER : point.yLabel === 'Project automation' ? YLabel.PROJECT_AUTOMATION : point.yLabel === 'Code completion' ? YLabel.CODE_COMPLETION : YLabel.TASK_AUTOMATION,
                  xRating: point.xRating,
                  yRating: point.yRating,
                  label: point.label,
                  url: point.url
              }));
              setDataPoints(mappedData);
          }
      } catch (error: any) {
          if (error.response && error.response.status === 401) {
            setQuadrantError('You need to be logged in to view this section');
          }else {
            setQuadrantError('An error occurred while fetching data');
          }
      }
  };
  
    fetchData();
  }, []);

  return (
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="prompt-techniques">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="Code" />

              <div className={"col-span-12 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section lg:col-span-9"}
                   id="techniques"
                   data-aos="fade-up"
                   data-aos-delay="100">
                <h1 className="text-bold">Coding <span className="text-header-gradient">Assistants</span></h1>
                <p>While the IPE framework provides a solid foundation for effective AI integration, coding has a few other challenges that you should consider. In this page, we dive into these challenges and how to address them. Keep in mind that the main framework still applies, even for coding. The IPE framework is a horizontal framework that applies to all AI integration domains, and the techniques we discuss here should be seen as an extension of the framework, not a replacement.</p>

                <hr />

                <h2 id="coding-landscape"><span className="text-header-gradient">Coding Assistant</span> Landscape</h2>
                <p>The following chart provides a high-level overview of the coding assistant landscape, mapping different solutions by their capabilities and integration levels. The x-axis represents the integration level, ranging from runable code snippets (Run) to solutions that can be published (Publish) or deployed (Deploy), and finally to fully integrated solutions that are part of your IDE (IDE Integration). The y-axis represents the capabilities of the solution, ranging from simple code completion to more advanced capabilities such as AI Engineers. Each solution is positioned on the chart based on its average user ratings for IDE integration or based on average review score (X) and code capabilities (Y), providing a quick visual representation of the landscape.</p>

                <div className="my-8 w-full">
                  {quadrantError ? <p className="text-red-500">{quadrantError}</p> :
                  <QuadrantChart
                    maxWidth={900}
                    aspectRatio={0.44}
                    xLabels={xLabels}
                    yLabels={yLabels}
                    dataPoints={dataPoints}
                    filterType={filterType}
                  /> }
                  <div className="flex items-center justify-center gap-6 mt-4 text-sm">
                    <div 
                      className="flex items-center gap-2 cursor-pointer"
                      onMouseEnter={() => setFilterType('rated')}
                      onMouseLeave={() => setFilterType(null)}
                    >
                      <div className="w-3 h-3 rounded-full bg-blue-500"></div>
                      <span>Rated tools</span>
                    </div>
                    <div 
                      className="flex items-center gap-2 cursor-pointer"
                      onMouseEnter={() => setFilterType('unrated')}
                      onMouseLeave={() => setFilterType(null)}
                    >
                      <div className="w-3 h-3 rounded-full bg-red-400"></div>
                      <span>Unrated tools</span>
                    </div>
                  </div>
                </div>

                <p className="italic">Only verified tools are shown in the list above. If you really have a tool added that we should make available to everyone, create a ticket in your dashboard to request it, and we'll check it out soon!</p>

                {/* <p>Having mastered various prompt engineering techniques, it's crucial to be aware of potential risks. In the next chapter, we will explore prompt injections, helping you understand the vulnerabilities and how to safeguard your AI interactions.</p>
                <BaseButton url={"/documentation/prompt-injections#prompt-injections"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                  Next: Prompt Injections
                </BaseButton> */}
              </div>
        </div>
      </section>
  );
}

export default CodingFrameworkDocumentation;
