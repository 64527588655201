import { GlobeIcon, HandCoins, InfoIcon, ShieldCheckIcon } from "lucide-react";
import { CategoryType, CategoryBadge } from "../CategoryBadge/CategoryBadge";
import { Tool } from "../PromptLibrary/types/Tool";
import { StarRating } from "./StarRating";
import { RatingViewMode } from "./types/RatingViewMode";
import { SelectedCategories } from "./types/SelectedCategories";
import ReactCountryFlag from "react-country-flag";

export const ToolItem = ({ 
    tool, 
    selectedCategory,
    selectedCategories,
    onViewDetails,
    ratingViewMode,
    profileUser = null,
    user = null
}: { 
    tool: Tool, 
    selectedCategory: CategoryType | null,
    selectedCategories: SelectedCategories,
    onViewDetails: (tool: Tool) => void,
    ratingViewMode: RatingViewMode,
    profileUser?: any,
    user?: any
}) => {

    // Update the getSortedRatings function to use the correct ratings based on view mode
    const getSortedRatings = (): Array<[string, number]> => {
        let ratingsToUse: { [key: string]: number } = {};

        // Get the ratings based on view mode
        switch (ratingViewMode) {
            case "user":
                ratingsToUse = tool.userRatings || {};
                break;
            case "our":
                ratingsToUse = tool.ourRatings || {};
                break;
            case "profile":
                ratingsToUse = tool.profileRatings || {};
                break;
            default:
                ratingsToUse = tool.ratings;
        }
        
        // Only include categories that exist in the tool's ratings
        return Object.entries(ratingsToUse)
            .sort((a, b) => {
                // First, prioritize all selected categories
                const aIsSelected = selectedCategory !== "All" && Array.from(selectedCategories).includes(a[0] as CategoryType);
                const bIsSelected = selectedCategory !== "All" && Array.from(selectedCategories).includes(b[0] as CategoryType);
                if (aIsSelected && !bIsSelected) return -1;
                if (!aIsSelected && bIsSelected) return 1;
                
                // Then sort by rating value (highest first)
                if (b[1] !== a[1]) {
                    return b[1] - a[1];
                }
                
                // If ratings are equal, sort alphabetically
                return a[0].localeCompare(b[0]);
            });
    };       


    // Update the getTopRatings function to show first 3 categories
    const getTopRatings = (): Array<[string, number]> => {
        return getSortedRatings().slice(0, 3);
    };

    // Handle click events
    const handleClick = (e: React.MouseEvent) => {
        onViewDetails(tool);
    };

    // Handle visit button click
    const handleVisitClick = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent triggering the card click
        window.open(tool.url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div 
            onClick={handleClick}
            className={`bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md 
                transition-all duration-200 p-6 space-y-4 cursor-pointer border flex flex-col
                ${tool.verified === 0 && user 
                    ? 'border-purple-200 dark:border-purple-400/60' 
                    : 'border-gray-100 dark:border-gray-700'} 
                    ${ratingViewMode === "user" && (Object.keys(tool.userRatings || {}).length === 0 && (!tool?.toolReviewUserRating || tool.toolReviewUserRating <= 0)) ? 'opacity-60' : ''}
                hover:border-gray-200 dark:hover:border-gray-600
                hover:-translate-y-2`}
        >
            {/* Header with Name, Badge, and Visit */}
            {tool.verified === 0 && user && (
                <span className="items-center -mt-3 px-3 py-0.5 rounded-full text-xs font-medium
                    bg-purple-100 text-purple-800 dark:bg-purple-900/30 dark:text-purple-300
                    border border-purple-200 dark:border-purple-800/30 flex items-center justify-center">
                    Personal
                </span>
            )}
            <div className="flex justify-between items-start gap-4">
                <div className="flex-1 min-w-0">
                    <div className="flex items-start gap-2 flex-wrap">
                        <h3 className="mb-2.5 text-lg font-semibold text-gray-900 dark:text-white truncate">
                            {tool.name}
                        </h3>
                    </div>
                </div>
                <button 
                    onClick={handleVisitClick}
                    className="flex items-center gap-1.5 px-3 py-1.5 rounded-full text-xs font-medium
                        transition-all duration-200 
                        bg-blue-50 text-blue-600 hover:bg-blue-100 hover:-translate-y-0.5
                        dark:bg-blue-500/10 dark:text-blue-400 dark:hover:bg-blue-500/20
                        border border-blue-100 dark:border-blue-500/20"
                >
                    Visit
                    <svg 
                        className="w-3.5 h-3.5 transition-transform group-hover:translate-x-0.5" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                    >
                        <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" 
                        />
                    </svg>
                </button>
            </div>

            {/* Description - Limited to 230 characters */}
            <div className="space-y-2">
                <p className="text-sm mb-0 text-gray-600 dark:text-gray-300 leading-relaxed mb-0.1 overflow-hidden h-[4.4rem]"
                    style={{
                        WebkitLineClamp: 3,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {tool.desc}
                </p>
            </div>

            {/* Pricing Information */}
            <div style={{ height: '1.4rem' }}>
            {(tool.pricing || tool.privacy || tool.dataCountry) && (
                <div className="flex items-center justify-between space-x-2">
                    {/* Pricing Information */}
                    <div className="flex items-center group">
                        {tool.pricing === "free" && (
                            <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                                <HandCoins className="w-3 h-3" />
                            </span>
                        )}
                        {tool.pricing === "paid" && (
                            <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
                                <HandCoins className="w-3 h-3" />
                            </span>
                        )}
                        {tool.pricing === "both" && (
                            <div className="flex space-x-2">
                                <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                                    <HandCoins className="w-3 h-3" />
                                </span>
                                <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
                                    <HandCoins className="w-3 h-3" />
                                </span>
                            </div>
                        )}
                        {tool.pricing === "trail" && (
                            <div className="flex space-x-2">
                                <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200">
                                    <HandCoins className="w-3 h-3" />
                                </span>
                                <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
                                    <HandCoins className="w-3 h-3" />
                                </span>
                            </div>
                        )}
                        {tool.pricing === "free-trail-paid" && (
                            <div className="flex space-x-2">
                                <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                                    <HandCoins className="w-3 h-3" />
                                </span>
                                <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200">
                                     <HandCoins className="w-3 h-3" />
                                </span>
                                <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
                                    <HandCoins className="w-3 h-3" />
                                </span>
                            </div>
                        )}

                        <div className="hidden group-hover:block mb-12 absolute z-10 bg-white dark:bg-gray-700 rounded-md px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-300 shadow-md">
                            <div className="flex items-center">
                                <InfoIcon className="w-4 h-4 text-gray-500 dark:text-gray-400 self-center mr-1" />
                                <span className="self-center">Price indicator</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-end relative">
                        {tool.dataCountry && tool.dataCountry.length > 0 && (
                            <div className="flex items-center space-x-1 group">
                                {tool.dataCountry.map((country, index) => (
                                    <span key={index} className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 border-gray-200 text-gray-800 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300 transition duration-200 hover:shadow-md">
                                        {country.toUpperCase() === "WORLD" ? (
                                            <GlobeIcon className="w-3 h-3" />
                                        ) : (
                                            <ReactCountryFlag countryCode={country} className="pt-[0.02rem] -ml-[0.05rem] w-3 h-3" />
                                        )}
                                    </span>
                                ))}
                                <div className="hidden group-hover:block absolute top-[-4rem] right-0 z-10 bg-white dark:bg-gray-700 rounded-md px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-300 shadow-md">
                                    <div className="flex items-center">
                                        <InfoIcon className="w-4 h-4 text-gray-500 dark:text-gray-400 self-center mr-1" />
                                        <span className="self-center">Data stored in: {tool.dataCountry.join(", ")}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {tool.privacy && (
                            <div className="flex items-center group">
                                <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium 
                                    ${tool.privacy === "minimal" ? "bg-green-100 border-green-200 text-green-800 dark:bg-green-900 dark:border-green-800 dark:text-green-200" : 
                                    tool.privacy === "limited" ? "bg-orange-100 border-orange-200 text-black dark:bg-orange-900 dark:border-orange-800 dark:text-orange-200" : 
                                    "bg-red-100 border-red-200 text-red-800 dark:bg-red-900 dark:border-red-800 dark:text-red-200"}
                                    transition duration-200 hover:shadow-md ml-2`}>
                                    <ShieldCheckIcon className="w-3 h-3" />
                                </span>
                                <div className="hidden group-hover:block absolute top-[-4rem] right-0 z-10 bg-white dark:bg-gray-700 rounded-md px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-300 shadow-md">
                                    <div className="flex items-center">
                                        <InfoIcon className="w-4 h-4 text-gray-500 dark:text-gray-400 self-center mr-1" />
                                        <span className="self-center">Privacy Risk indication</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            )}
            </div>

            {/* Categories and Ratings - Limited to 3 */}
            <div className="pt-2 border-t border-gray-100 dark:border-gray-700 flex-grow">
                <div className="pt-1 text-xs font-medium text-gray-500 dark:text-gray-400 mb-4 flex items-center justify-between">
                    <span>Categories & Ratings</span>
                    {Object.keys(tool.ratings).includes('Text') && 
                     (Object.keys(tool.ratings).includes('Images') || Object.keys(tool.ratings).includes('Video') || Object.keys(tool.ratings).includes('Audio') || Object.keys(tool.ratings).includes('Podcasts') || Object.keys(tool.ratings).includes('Visuals') || Object.keys(tool.ratings).includes('Avatar') || Object.keys(tool.ratings).includes('Voice')) && (
                        <span className="inline-flex items-center px-1.5 py-0.5 rounded-full text-[10px] font-medium 
                            bg-indigo-100 text-indigo-800 dark:bg-indigo-900/30 dark:text-indigo-300
                            border border-indigo-200 dark:border-indigo-800/30">
                            Multi-Modal
                        </span>
                    )}
                </div>
                <div className="flex flex-col gap-2">
                    {ratingViewMode === "user" && Object.keys(tool.userRatings || {}).length === 0 ? (
                        <>
                            {/* <div className="text-[12px] text-gray-500 dark:text-gray-400 text-center py-1">
                                You haven't rated this tool yet
                            </div> */}
                            <div className="flex flex-wrap gap-2 mt-2">
                                {Object.keys(tool.ratings)  // Use the actual categories from the tool
                                    .slice(0, 3)
                                    .map((category) => (
                                        <CategoryBadge 
                                            key={category} 
                                            category={category as CategoryType}
                                        />
                                    ))}
                                {Object.keys(tool.ratings).length > 3 && (
                                    <div className="flex items-center justify-center w-full">
                                        <span className="text-xs text-gray-500 dark:text-gray-400 mx-auto">
                                            +{Object.keys(tool.ratings).length - 3} more
                                        </span>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : ratingViewMode === "profile" && Object.keys(tool.profileRatings || {}).length === 0 ? (
                        <>
                            <div className="text-sm text-gray-500 dark:text-gray-400 text-center py-2">
                                The user didn't rate this tool yet!
                            </div>
                            <div className="flex flex-wrap gap-2 mt-2">
                                {Object.keys(tool.ratings)  // Use the actual categories from the tool
                                    .slice(0, 3)
                                    .map((category) => (
                                        <CategoryBadge 
                                            key={category} 
                                            category={category as CategoryType}
                                        />
                                    ))}
                                {Object.keys(tool.ratings).length > 3 && (
                                    <span className="text-xs text-gray-500 dark:text-gray-400 self-center">
                                        +{Object.keys(tool.ratings).length - 3} more
                                    </span>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            {getTopRatings().map(([category, rating], idx) => (
                                <div 
                                    key={idx} 
                                    className={`flex items-center justify-between gap-2 p-2 rounded-lg transition-colors
                                        ${selectedCategories.has(category as CategoryType)
                                            ? "bg-blue-50 dark:bg-blue-900/20" 
                                            : ""
                                        }`}
                                >
                                    <CategoryBadge category={category as CategoryType} />
                                    <StarRating rating={rating} />
                                </div>
                            ))}
                            {getTopRatings().length < Object.keys(tool.ratings).length && (
                                <div className="text-xs text-gray-500 dark:text-gray-400 text-center">
                                    +{Object.keys(tool.ratings).length - getTopRatings().length} more
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            { 
                ratingViewMode === "user" ?
                tool.toolReviewUserRating && tool.toolReviewUserRating > 0 && 
                <div className="text-xs border-t mt-2 border-gray-100 mr-2 dark:border-gray-700 pt-4 font-medium text-gray-500 dark:text-gray-400 flex items-center justify-between">
                    <span>Your tool review</span>
                    <StarRating rating={tool.toolReviewUserRating} />
                </div> 
                : ratingViewMode === "profile" ?
                tool.toolReviewProfileRating && tool.toolReviewProfileRating > 0 && 
                <div className="text-xs border-t mt-2 border-gray-100 mr-2 dark:border-gray-700 pt-4 font-medium text-gray-500 dark:text-gray-400 flex items-center justify-between">
                    <span className="mr-3">{profileUser.name} tool review</span>
                    <StarRating rating={tool.toolReviewProfileRating} />
                </div> 
                :
                tool.toolReviewRating && tool.toolReviewRating > 0 && 
                <div className="text-xs border-t mt-2 border-gray-100 mr-2 dark:border-gray-700 pt-4 font-medium text-gray-500 dark:text-gray-400 flex items-center justify-between">
                    <span>User tool reviews</span>
                    <StarRating rating={tool.toolReviewRating} />
                </div> 
            }
            
        </div>
    );
};