import Dropdown from '../Dropdown'
import ResponsiveNavLink, { ResponsiveNavButton } from '../ResponsiveNavLink'
import { DropdownButton } from '../DropdownLink'
import { useAuth } from '../../../hooks/auth'
import React, { useState } from 'react'
import CustomNavLink from '../NavLink';
import {Link, useNavigate} from 'react-router-dom';
import {ReactComponent as Logo} from "../../../assets/img/logo/crafting-ai-logo.svg";
import RoleEnum from "../../enums/RoleEnum";

const Navigation = ({ user }) => {
  let navigate = useNavigate();
  const { logout } = useAuth()
  const [open, setOpen] = useState(false)

  return (
    <nav className="bg-white dark:bg-gray-800 border-b border-gray-100 dark:border-gray-700">
      {/* Primary Navigation Menu */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            {/* Logo */}
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                <Logo className="dark:fill-white" />
              </Link>
            </div>
            {/* Navigation Links */}
            <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
              <CustomNavLink
                to="/dashboard/overview"
              >
                Dashboard
              </CustomNavLink>

              { user?.role >= RoleEnum.Trainer && (
                  <CustomNavLink
                      to="/dashboard/trainer"
                  >
                    Trainer
                  </CustomNavLink>) }

              { user?.role >= RoleEnum.Admin && (
              <CustomNavLink
                  to="/dashboard/admin"
              >
                Admin
              </CustomNavLink>) }
            </div>
          </div>
          {/* Settings Dropdown */}
          <div className="hidden sm:flex sm:items-center sm:ml-6">
            <Dropdown
              align="right"
              width="48"
              trigger={
                <button className="flex items-center text-sm font-medium
                 text-gray-500 dark:text-gray-300 hover:text-gray-700
                 dark:hover:text-gray-200 focus:outline-none
                 transition duration-150 ease-in-out">
                  <div>{user?.name}</div>
                  <div className="ml-1">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        // eslint-disable-next-line max-len
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </button>
              }>
              {/* Authentication */}
              <DropdownButton onClick={() => { navigate("/") }}>
                Back to website
              </DropdownButton>
              <DropdownButton onClick={() => { navigate("/dashboard/profile") }}>
                Profile
              </DropdownButton>
              <DropdownButton onClick={logout}>
                Logout
              </DropdownButton>
            </Dropdown>
          </div>
          {/* Hamburger */}
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              onClick={() => setOpen(open => !open)}
              className="inline-flex items-center justify-center p-2
              rounded-md text-gray-400 dark:text-gray-300 hover:text-gray-500
              dark:hover:text-gray-200 hover:bg-gray-100
              dark:hover:bg-gray-700 focus:outline-none focus:bg-gray-100
               dark:focus:bg-gray-700 focus:text-gray-500
               dark:focus:text-gray-200 transition duration-150 ease-in-out">
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24">
                {open ? (
                  <path
                    className="inline-flex"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    className="inline-flex"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Responsive Navigation Menu */}
      {open && (
        <div className="block sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            <ResponsiveNavLink
              to="/dashboard/overview"
            >
              Dashboard
            </ResponsiveNavLink>
            { user?.role >= RoleEnum.Trainer && (
                <ResponsiveNavLink
                    to="/dashboard/trainer"
                >
                  Trainer
                </ResponsiveNavLink>) }
            { user?.role >= RoleEnum.Admin && (
                <ResponsiveNavLink
                    to="/dashboard/admin"
                >
                  Admin
                </ResponsiveNavLink>) }
          </div>
          {/* Responsive Settings Options */}
          <div className="pb-1 border-gray-200 dark:border-gray-700">
            <div className="flex items-center">
              <ResponsiveNavButton onClick={() => { navigate("/dashboard/profile")}}>
                <div className={"flex"}>
                  <div className="flex-shrink-0">
                    <svg
                      className="h-10 w-10 fill-current text-gray-400 dark:text-gray-200"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                      <div className="font-medium text-base text-gray-800 dark:text-gray-200">
                        {user?.name}
                      </div>
                      <div className="font-medium text-sm text-gray-500 dark:text-gray-400">
                        {user?.email}
                      </div>
                  </div>
                    </div>
                  </ResponsiveNavButton>
                </div>
            <div className="mt-3 space-y-1">
              {/* Authentication */}
              <ResponsiveNavButton onClick={logout}>
                Logout
              </ResponsiveNavButton>
            </div>
          </div>
        </div>
      )}
    </nav>
  )
}

export default Navigation
