import TicketStatusEnum from "../../enums/TicketStatusEnum";
import React, {useEffect, useState} from "react";
import TicketUpdate from "./TicketUpdates";
import ReplyTicketModal from "./ReplyTicketModal";
import StatusLabel from "../StatusLabel";
import axios from "../../../libs/axios";
import {useAuth} from "../../../hooks/auth";

const Tickets = ({tickets: initialTickets, category = null, filterCategory = null, filterStatus = null, showOnlyStatus = null, showMax = null}) => {
    const { user } = useAuth({ middleware: 'auth' })
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [currentTicketId, setCurrentTicketId] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [tickets, setTickets] = useState(initialTickets || []);

    function replyToTicket(ticketId) {
        setCurrentTicketId(ticketId);
        setShowReplyModal(true);
    }

    async function handleReplySubmit() {
        try {
            axios.post(`/ticket/reply`, {
                ticket_id: currentTicketId,
                message: replyText
            }).then(response => {
                // Update the ticket list
                setTickets(tickets.map(ticket => ticket.id === currentTicketId ? {
                    ...ticket,
                    updates: [...ticket.updates, {
                        user: user,
                        description: replyText,
                        updated_at: new Date().toISOString()
                    }],
                    status: response.data.new_ticket_status // update the status of the ticket
                } : ticket));

                const currentOpenTickets = localStorage.getItem('open_tickets') ? parseInt(localStorage.getItem('open_tickets')) : 0;
                localStorage.setItem('open_tickets', currentOpenTickets - 1);
            }).catch(() => {
                window.location.reload();
            });

            setShowReplyModal(false);
            setReplyText('');

        } catch (error) {
            if (error.status === 401) {
                window.location.reload()
            }
        }
    }


    useEffect(() => {
        let filteredTickets = [...tickets];

        if (category) {
            filteredTickets = filteredTickets.filter(ticket => ticket.category === category);
        }
        if (filterCategory) {
            filteredTickets = filteredTickets.filter(ticket => ticket.category !== filterCategory);
        }

        if (filterStatus !== null) {
            filteredTickets = filteredTickets.filter(ticket => ticket.status !== filterStatus);
        }

        if (showOnlyStatus !== null) {
            filteredTickets = filteredTickets.filter(ticket => ticket.status === showOnlyStatus);
        }

        if (showMax !== null) {
            filteredTickets = filteredTickets.slice(0, showMax);
        }

        setTickets(filteredTickets);
    }, [category, filterStatus, showOnlyStatus, showMax]);

    return(
        <div className="grid grid-cols-1 gap-6 dark:bg-gray-800">
            {tickets && tickets.map((ticket, index) => (
                <div key={index} className="group border border-gray-100 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 dark:border-gray-700 bg-white dark:bg-gray-700 overflow-hidden">
                    {/* Ticket Header */}
                    <div className="px-6 py-4 border-b border-gray-100 dark:border-gray-700 flex items-center">
                        {/* Fixed width for ticket number */}
                        <div className="w-[50px] flex-shrink-0">
                            <div className="w-8 h-8 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center">
                                <span className="text-sm font-semibold text-blue-600 dark:text-blue-300">#{tickets.length - index}</span>
                            </div>
                        </div>
                        
                        {/* Title takes remaining space */}
                        <h2 className="text-lg font-semibold text-gray-900 dark:text-white flex-grow pt-4">
                            {ticket.title || 'Untitled Ticket'}
                        </h2>
                        
                        {/* Fixed width for status */}
                        <div className="w-[95px] flex-shrink-0 flex justify-end">
                            { /** Switching status so its clear for the user (saved is for moderation) */}
                            <StatusLabel status={ticket.status === TicketStatusEnum.Pending ? TicketStatusEnum.Open : ticket.status === TicketStatusEnum.Open ? TicketStatusEnum.Pending : ticket.status } />
                        </div>
                    </div>

                    {/* Ticket Content */}
                    <div className="p-6 space-y-6">
                        {/* Metadata */}
                        <div className="flex items-center gap-6 text-sm">
                            <div className="flex items-center gap-2 text-gray-500 dark:text-gray-300">
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <span>
                                    {ticket.created_at ? new Date(ticket.created_at).toLocaleString('en-US', { 
                                        month: 'long', 
                                        day: 'numeric', 
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    }) : 'N/A'}
                                </span>
                            </div>
                        </div>

                        {/* Description */}
                        <div className="space-y-2">
                            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-300">Description</h3>
                            <div className="text-gray-700 dark:text-gray-300 bg-gray-50 dark:bg-gray-800 rounded-lg p-4">
                                {ticket.description}
                            </div>
                        </div>

                        {/* Updates */}
                        {ticket.updates && ticket.updates.length > 0 && (
                            <div className="space-y-4">
                                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-300">Recent Updates</h3>
                                <div className="space-y-3">
                                    {ticket.updates?.slice(-3).map((update, index) => (
                                        <TicketUpdate key={index} update={update} />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Actions */}
                    {(ticket.status !== TicketStatusEnum.Pending && ticket.status !== TicketStatusEnum.Closed) && (
                        <div className="px-6 py-4 bg-gray-50 border-t border-gray-100 dark:bg-gray-800 dark:border-gray-700">
                            <button 
                                onClick={() => replyToTicket(ticket.id)}
                                className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-lg
                                    transition-all duration-200 hover:-translate-y-0.5 shadow-sm hover:shadow
                                    bg-gradient-to-r from-[#468ef9] to-[#0c66ee] hover:from-[#3d7ed9] hover:to-[#0a56c7]
                                    text-white"
                            >
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
                                </svg>
                                Reply
                            </button>
                        </div>
                    )}
                </div>
            ))}

            {/* Empty State */}
            {tickets && tickets.length === 0 && (
                <div className="border border-gray-100 rounded-xl p-8 bg-white dark:bg-gray-800 text-center">
                    <div className="flex flex-col items-center gap-3">
                        <div className="w-12 h-12 rounded-full bg-gray-100 dark:bg-gray-700 flex items-center justify-center">
                            <svg className="w-6 h-6 text-gray-400 dark:text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
                            </svg>
                        </div>
                        <p className="text-gray-500 dark:text-gray-300 font-medium">No tickets found</p>
                    </div>
                </div>
            )}

            {/* Reply Modal */}
            {showReplyModal && (
                <ReplyTicketModal
                    showReplyModal={showReplyModal}
                    setShowReplyModal={setShowReplyModal}
                    ticketList={tickets}
                    currentTicketId={currentTicketId}
                    replyText={replyText}
                    setReplyText={setReplyText}
                    handleReplySubmit={handleReplySubmit}
                    status={tickets.find(ticket => ticket.id === currentTicketId)?.status}
                />
            )}
        </div>
    );
}

export default Tickets;