export const StarRating = ({ rating }: { rating: number | string }) => {
    // Parse the rating and ensure it's a valid number
    const numericRating = typeof rating === 'string' 
        ? parseFloat(rating)
        : rating;

    const getFillPercentage = (star: number) => {
        const starFloor = Math.floor(numericRating);

        // Fully filled stars
        if (star <= starFloor) { return 100; }
        
        // First partially filled star
        if (star === starFloor + 1) { return ((numericRating - starFloor) * 100); }
        
        // All other stars
        return 0;
    };


    return (
        <div className="flex items-center gap-1">
            {[1, 2, 3, 4, 5].map((star) => {
                const fillPercentage = getFillPercentage(star);
                
                return (
                    <svg
                        key={star}
                        className="w-3.5 h-3.5"
                        viewBox="0 0 20 20"
                    >
                        <defs>
                            {fillPercentage > 0 && fillPercentage < 100 && (
                                <mask id={`star-mask-${star}`}>
                                    <rect x="0" y="0" width={`${fillPercentage}%`} height="100%" fill="white" />
                                    <rect x={`${fillPercentage}%`} y="0" width={`${100 - fillPercentage}%`} height="100%" fill="black" />
                                </mask>
                            )}
                        </defs>
                        
                        {/* Base star (always visible) */}
                        <path
                            className="text-gray-300 dark:text-gray-600"
                            fill="currentColor"
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        />
                        
                        {/* Filled portion (yellow) */}
                        {fillPercentage > 0 && (
                            <path
                                className="text-yellow-400"
                                fill="currentColor"
                                mask={fillPercentage < 100 ? `url(#star-mask-${star})` : undefined}
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            />
                        )}
                    </svg>
                );
            })}
        </div>
    );
};