import { useState, useEffect } from 'react';
import DashboardCard from "../../../components/dashboard/dashboard/DashboardCard";
import AppLayout from '../../../components/dashboard/Layouts/AppLayout';
import axios from '../../../libs/axios';
import Pagination from "../../../components/dashboard/dashboard/Pagination";
import { CategoryBadge, CategoryType, CATEGORIES } from '../../../components/CategoryBadge/CategoryBadge';

interface Tool {
  id: string;
  name: string;
  desc: string;
  url: string;
  categories: CategoryType[];
  verified: boolean;
  addedBy?: string;
  pricing?: "free" | "paid" | "both" | "trail" | "free-trail-paid" | undefined;
  privacy?: "minimal" | "limited" | "high" | undefined;
  dataCountry?: string[] | undefined;
  output_option?: string;
  automation?: string;
}

interface ApiTool {
  id: number;
  name: string;
  desc: string;
  url: string;
  verified: boolean;
  addedBy?: string;
  ratings: {
    [key in CategoryType]?: string;
  };
  userRatings: {
    [key in CategoryType]?: number;
  };
  ourRatings: {
    [key in CategoryType]?: number;
  };
  pricing?: "free" | "paid" | "both" | "trail" | "free-trail-paid" | undefined;
  privacy?: "minimal" | "limited" | "high" | undefined;
  dataCountry?: string[] | undefined;
  output_option?: string;
  automation?: string;
}

export default function AdminTools() {
  const [tools, setTools] = useState<Tool[]>([]);
  const [editingTool, setEditingTool] = useState<Tool | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [toolToDelete, setToolToDelete] = useState<Tool | null>(null);
  const [urlError, setUrlError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [isLoading, setIsLoading] = useState(true);

  // Fetch tools on component mount
  useEffect(() => {
    fetchTools();
  }, []);

  const fetchTools = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('/admin/tools');
      
      // Transform the API response to match our Tool interface
      const transformedTools: Tool[] = response.data.map((apiTool: ApiTool) => ({
        id: apiTool.id.toString(),
        name: apiTool.name,
        desc: apiTool.desc,
        url: apiTool.url,
        verified: Boolean(apiTool.verified),
        pricing: apiTool.pricing,
        privacy: apiTool.privacy,
        dataCountry: apiTool.dataCountry,
        addedBy: apiTool.addedBy,
        output_option: apiTool.output_option,
        automation: apiTool.automation,
        categories: Object.entries(apiTool.ratings || {})
          .map(([category]) => category as CategoryType)
      }));

      setTools(transformedTools);
    } catch (error: any) {
      console.error('Failed to fetch tools:', error);
      // Add more detailed error logging
      if (error?.response) {
        console.error('API Error:', error.response.data);
      }
      setTools([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditTool = async (tool: Tool) => {
    try {
      // Update to include verified status
      await axios.put(`/admin/tools/edit/${tool.id}`, {
        name: tool.name,
        desc: tool.desc,
        url: tool.url,
        pricing: tool.pricing,
        privacy: tool.privacy,
        dataCountry: tool.dataCountry,
        verified: tool.verified,
        output_option: tool.output_option,
        automation: tool.automation
      });

      // Get the original tool data to compare categories
      const originalTool = tools.find(t => t.id === tool.id);
      if (!originalTool) return;

      // Find new categories to add
      const newCategories = tool.categories.filter(
        category => !originalTool.categories.includes(category)
      );

      // Find categories to remove
      const categoriesToRemove = originalTool.categories.filter(
        category => !tool.categories.includes(category)
      );

      // Add new categories with rating 0
      if (newCategories.length > 0) {
        const addPromises = newCategories.map(category => 
          axios.post(`/tools/${tool.id}/rate`, {
            ratings: {
              [category]: 0
            }
          })
        );
        await Promise.all(addPromises);
      }

      // Remove categories using DELETE endpoint
      if (categoriesToRemove.length > 0) {
        const removePromises = categoriesToRemove.map(category => 
          axios.delete(`/tools/${tool.id}/rate`, {
            data: {
              category: category
            }
          })
        );
        await Promise.all(removePromises);
      }
      
      setEditingTool(null);
      fetchTools();
    } catch (error) {
      console.error('Failed to update tool:', error);
    }
  };

  const handleDeleteTool = async (tool: Tool) => {
    try {
      await axios.delete(`/admin/tools/delete/${tool.id}`);
      setIsDeleteModalOpen(false);
      setToolToDelete(null);
      fetchTools(); // Refresh the list
    } catch (error) {
      console.error('Failed to delete tool:', error);
    }
  };

  const [newTool, setNewTool] = useState<Omit<Tool, 'id'>>({
    name: '',
    desc: '',
    url: '',
    categories: [],
    verified: false,
  });

  const isValidUrl = (urlString: string): boolean => {
    // Basic pattern for valid URLs
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    
    try {
      // First check if it matches basic URL pattern
      if (!urlPattern.test(urlString)) {
        return false;
      }
      
      // Then try to construct URL object (adds additional validation)
      const url = new URL(urlString.startsWith('http') ? urlString : `https://${urlString}`);
      
      // Make sure we have at least a valid domain
      return url.hostname.includes('.');
    } catch {
      return false;
    }
  };

  const handleAddTool = async (e: React.FormEvent) => {
    e.preventDefault();
    setUrlError('');
    
    let urlToValidate = newTool.url;
    if (!urlToValidate.startsWith('http')) {
      urlToValidate = `https://${urlToValidate}`;
    }

    if (!isValidUrl(urlToValidate)) {
      setUrlError('Please enter a valid URL (e.g., example.com or https://example.com)');
      return;
    }

    try {
      // First create the tool
      const response = await axios.post('/admin/tools/add', {
        name: newTool.name,
        desc: newTool.desc,
        url: urlToValidate,
        pricing: newTool.pricing,
        privacy: newTool.privacy,
      });

      const toolId = response.data.tool.id; // Assuming the API returns the new tool's ID

      // Then add ratings for each category
      const ratingPromises = newTool.categories.map(category => 
        axios.post(`/tools/${toolId}/rate`, {
          ratings: {
            [category]: 0
          }
        })
      );

      await Promise.all(ratingPromises);
      
      setNewTool({ name: '', desc: '', url: '', categories: [], verified: false });
      fetchTools();
    } catch (error) {
      console.error('Failed to add tool:', error);
    }
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value as CategoryType);
    setNewTool({ ...newTool, categories: selectedOptions });
  };

  const handleEditCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!editingTool) return;
    const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value as CategoryType);
    setEditingTool({ ...editingTool, categories: selectedOptions });
  };

    const handleDeleteRegion = (index: number) => {
        if (!editingTool || !editingTool.dataCountry) return; // Ensure dataCountry is defined
        setEditingTool(prev => {
            if (!prev) return null; // Handle the case where prev is null
            return {
                ...prev,
                dataCountry: prev.dataCountry ? prev.dataCountry.filter((_, i) => i !== index) : [] // Filter out the item at the specified index
            };
        });
    };

  const [searchQuery, setSearchQuery] = useState('');
  const [showUnverified, setShowUnverified] = useState(false); // State for the toggle

  // Filter tools based on search query and toggle state
  const filteredTools = tools.filter(tool => {
      const matchesSearch = tool.name.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesVerification = showUnverified ? !tool.verified : true; // Show unverified if toggle is on
      return matchesSearch && matchesVerification;
  });

  const totalPages = Math.ceil(filteredTools.length / itemsPerPage);
  const currentTools = filteredTools.slice(currentPage * itemsPerPage - itemsPerPage, currentPage * itemsPerPage);

  const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default enter action
            if (editingTool && editingTool.dataCountry) {
                setEditingTool(prev => {
                    if (!prev) return null; // Handle the case where prev is null
                    return {
                        ...prev,
                        dataCountry: [...(prev.dataCountry || []), inputValue.trim()], // Add the trimmed input value
                    };
                });
                setInputValue(''); // Clear the input field
            }
        }
    };

  // Add useEffect for keyboard handling
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsDeleteModalOpen(false);
            setToolToDelete(null);
        }
    };

    // Add event listener when modal is open
    if (isDeleteModalOpen) {
        document.addEventListener('keydown', handleEscape);
    }

    // Cleanup function to remove event listener
    return () => {
        document.removeEventListener('keydown', handleEscape);
    };
  }, [isDeleteModalOpen]); // Only re-run effect when modal state changes

  return (
    <AppLayout
                header={
                    <h2 className="font-semibold text-xl dark:text-white leading-tight">
                        Admin Dashboard - Tools
                    </h2>
                }>
                <div className="py-12 mx-3 lg:mx-0 dark:bg-gray-900">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <DashboardCard title="Add New Tool" action={null}>
                                <form onSubmit={handleAddTool} className="space-y-4">
                                    <div>
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                                            Tool Name
                                        </label>
                                        <input
                                            id="name"
                                            type="text"
                                            value={newTool.name}
                                            onChange={(e) => setNewTool({ ...newTool, name: e.target.value })}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:text-gray-200"
                                            required
                                        />
                                    </div>
                                    
                                    <div>
                                        <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                                            Description
                                        </label>
                                        <input
                                            id="description"
                                            type="text"
                                            value={newTool.desc}
                                            onChange={(e) => setNewTool({ ...newTool, desc: e.target.value })}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:text-gray-200"
                                            required
                                        />
                                    </div>
                                    
                                    <div>
                                        <label htmlFor="url" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                                            URL
                                        </label>
                                        <input
                                            id="url"
                                            type="text"
                                            value={newTool.url}
                                            onChange={(e) => {
                                                setNewTool({ ...newTool, url: e.target.value });
                                                setUrlError(''); // Clear error when user types
                                            }}
                                            className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:text-gray-200 ${
                                                urlError ? 'border-red-500' : 'border-gray-300'
                                            }`}
                                            required
                                        />
                                        {urlError && (
                                            <p className="mt-1 text-sm text-red-600 dark:text-red-300">
                                                {urlError}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                                            Pricing
                                        </label>
                                        <select
                                            id="pricing"
                                            value={newTool.pricing}
                                            onChange={(e) => setNewTool({ ...newTool, pricing: e.target.value as "free" | "paid" | "both" | "trail" | "free-trail-paid" | undefined })}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:text-gray-200"
                                        >
                                            <option value="">Select Pricing</option>
                                            <option value="free">Free</option>
                                            <option value="paid">Paid</option>
                                            <option value="trail">Trail & Paid</option>
                                            <option value="free-trail-paid">Free, Trail & Paid</option>
                                            <option value="both">Free & Paid</option>
                                            <option value="">Unknown / Other</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="privacy" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                                            Privacy Risk
                                        </label>
                                        <select
                                            id="privacy"
                                            value={newTool.privacy}
                                            onChange={(e) => setNewTool({ ...newTool, privacy: e.target.value as "minimal" | "limited" | "high" | undefined })}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:text-gray-200"
                                        >
                                            <option value="">Select Privacy Risk</option>
                                            <option value="minimal">Minimal</option>
                                            <option value="limited">Liminted</option>
                                            <option value="high">High</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="categories" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                                            Categories
                                        </label>
                                        <select
                                            id="categories"
                                            multiple
                                            value={newTool.categories}
                                            onChange={handleCategoryChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:text-gray-200"
                                            required
                                            size={4}
                                        >
                                            {Object.entries(CATEGORIES).filter(([category]) => category !== "All").map(([category, categoryData]) => (
                                                <option key={category} value={category}>
                                                    {category}
                                                </option>
                                            ))}
                                        </select>
                                        <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                                            Hold Ctrl (Windows) or Command (Mac) to select multiple categories
                                        </p>
                                    </div>

                                    <button
                                        type="submit"
                                        className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-300"
                                    >
                                        Add Tool
                                    </button>
                                </form>
                            </DashboardCard>

                            <DashboardCard title="Tools Overview" action={null}>

                                <div className="divide-y divide-gray-100 dark:divide-gray-700">
                                    {isLoading ? (
                                        <div className="py-4 text-center text-gray-500 dark:text-gray-200">Loading...</div>
                                    ) : tools.length === 0 ? (
                                        <div className="py-4 text-center text-gray-500 dark:text-gray-200">No tools found</div>
                                    ) : (
                                        <div>
                                        <div className="flex items-center space-x-8 mb-4">
                                            <input
                                                type="text"
                                                placeholder="Search tools..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                className="flex-1 border bg-white dark:bg-gray-800 rounded-md p-2"
                                            />
                                            <div className="flex items-center">
                                            <button
                                                onClick={() => setShowUnverified(!showUnverified)}
                                                className={`relative inline-flex mr-3 items-center h-6 rounded-full w-11 transition-colors duration-200 ease-in-out ${
                                                    showUnverified ? 'bg-blue-600 dark:bg-blue-400' : 'bg-gray-200 dark:bg-gray-900'
                                                }`}
                                            >
                                                <span
                                                    className={`transform transition-transform duration-200 ease-in-out ${
                                                        showUnverified ? 'translate-x-5' : 'translate-x-0'
                                                    } inline-block w-5 h-5 bg-white dark:bg-gray-700 rounded-full`}
                                                />
                                            </button>
                                                <label className="text-gray-700 dark:text-gray-300">
                                                    Unverified Only
                                                </label>
                                            </div>
                                        </div>

                                        {currentTools.map((tool) => (
                                            <div key={tool.id} className="py-4 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors px-2 relative">
                                                <span className={`absolute top-2 right-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                                    tool.verified 
                                                        ? 'bg-green-100 text-green-800 dark:bg-green-500 dark:text-green-300'
                                                        : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
                                                }`}>
                                                    {tool.verified ? 'Verified' : (
                                                        <span className="inline-flex items-center">
                                                            Unverified
                                                            {tool.addedBy && (
                                                                <span className="ml-1 text-gray-500 dark:text-gray-400">
                                                                    (added by {tool.addedBy})
                                                                </span>
                                                            )}
                                                        </span>
                                                    )}
                                                </span>

                                                <div className="flex items-center justify-between">
                                                    <div className="min-w-0 flex-1">
                                                        <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100 leading-5 mb-2">{tool.name}</h3>
                                                        <div className="flex flex-wrap gap-1 mb-2">
                                                            {tool.categories?.map((category) => (
                                                                <CategoryBadge key={category} category={category} />
                                                            ))}
                                                        </div>
                                                        <p className="text-xs text-gray-500 dark:text-gray-400 truncate">{tool.desc}</p>
                                                        <div className="text-xs text-gray-500 dark:text-gray-400">
                                                            {tool.pricing &&
                                                            <div className="text-sm text-gray-500 dark:text-gray-400">
                                                                <span className="font-semibold">Pricing:</span>
                                                                {tool.pricing === "free" && <span className="ml-1 text-green-600 dark:text-green-300">Free</span>}
                                                                {tool.pricing === "paid" && <span className="ml-1 text-red-600 dark:text-red-300">Paid</span>}
                                                                {tool.pricing === "both" && <span className="ml-1 text-yellow-600 dark:text-yellow-300">Free & Paid</span>}
                                                                {tool.pricing === "trail" && <span className="ml-1 text-orange-600 dark:text-orange-300">Trail & Paid</span>}
                                                                {tool.pricing === "free-trail-paid" && <span className="ml-1 text-purple-600 dark:text-purple-300">Free, Trail & Paid</span>}
                                                            </div> }
                                                        </div>
                                                            {tool.privacy &&
                                                            <div className="text-sm text-gray-500 dark:text-gray-400">
                                                                <span className="font-semibold">Privacy:</span>
                                                                {tool.privacy === "minimal" && <span className="ml-1 text-green-600 dark:text-green-300">Minimal data collection</span>}
                                                                {tool.privacy === "limited" && <span className="ml-1 text-yellow-600 dark:text-yellow-300">Limited data collection</span>}
                                                                {tool.privacy === "high" && <span className="ml-1 text-red-600 dark:text-red-300">High data collection</span>}
                                                            </div> }

                                                        <a href={tool.url} className="text-xs text-blue-500 dark:text-blue-300 hover:text-blue-700 dark:hover:text-blue-400 truncate inline-block" target="_blank" rel="noopener noreferrer">
                                                            {tool.url}
                                                        </a>
                                                    </div>
                                                    <div className="ml-4 flex-shrink-0 flex items-center space-x-2">
                                                        <button
                                                            onClick={() => setEditingTool(tool)}
                                                            className="px-3 py-1 text-xs font-medium text-blue-600 dark:text-blue-300 hover:text-blue-700 dark:hover:text-blue-400 bg-blue-50 dark:bg-blue-700 rounded-md transition-colors"
                                                        >
                                                            Edit
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setToolToDelete(tool);
                                                                setIsDeleteModalOpen(true);
                                                            }}
                                                            className="px-3 py-1 text-xs font-medium text-red-600 dark:text-red-300 hover:text-red-700 dark:hover:text-red-400 bg-red-50 dark:bg-red-700 rounded-md transition-colors"
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        }</div>
                                    )}
                                </div>
                                
                                {/* Pagination */}
                                {!isLoading && totalPages > 1 && (
                                    <div className="mt-4">
                                        <Pagination 
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={setCurrentPage}
                                        />
                                    </div>
                                )}
                            </DashboardCard>
                        </div>
                    </div>
                </div>

                {/* Edit Modal */}
                {editingTool && (
                    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center p-4 z-50 overflow-y-auto">
                        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-2xl max-w-md w-full p-6 my-8">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleEditTool(editingTool);
                            }} className="space-y-4 max-h-[80vh] overflow-y-auto">
                                <div className="flex justify-between items-center mb-6 sticky top-0 bg-white dark:bg-gray-800 py-2 z-10">
                                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Edit Tool</h2>
                                    <button
                                        type="button"
                                        onClick={() => setEditingTool(null)}
                                        className="text-gray-400 hover:text-gray-500 p-1 dark:text-gray-500 dark:hover:text-gray-400"
                                    >
                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-white mb-1">Name</label>
                                    <input
                                        type="text"
                                        value={editingTool.name}
                                        onChange={(e) => setEditingTool({ ...editingTool, name: e.target.value })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-white"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-white mb-1">Description</label>
                                    <input
                                        type="text"
                                        value={editingTool.desc}
                                        onChange={(e) => setEditingTool({ ...editingTool, desc: e.target.value })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-white"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-white mb-1">Pricing</label>
                                    <select
                                        value={editingTool.pricing}
                                        onChange={(e) => setEditingTool({ ...editingTool, pricing: e.target.value as "free" | "paid" | "both" | "trail" | "free-trail-paid" | undefined })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-white"
                                    >
                                        <option value="">Select Pricing</option>
                                        <option value="free">Free</option>
                                        <option value="paid">Paid</option>
                                        <option value="trail">Trail & Paid</option>
                                        <option value="free-trail-paid">Free, Trail & Paid</option>
                                         <option value="both">Free & Paid</option>
                                        <option value="">Unknown / Other</option>
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-white mb-1">Privacy</label>
                                    <select
                                        value={editingTool.privacy}
                                        onChange={(e) => setEditingTool({ ...editingTool, privacy: e.target.value as "minimal" | "limited" | "high" | undefined })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-white"
                                    >
                                        <option value="">Select Privacy</option>
                                        <option value="minimal">Minimal</option>
                                        <option value="limited">Limited</option>
                                        <option value="high">High</option>
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-white mb-1">URL</label>
                                    <input
                                        type="text"
                                        value={editingTool.url}
                                        onChange={(e) => setEditingTool({ ...editingTool, url: e.target.value })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-white"
                                        required
                                    />
                                </div>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="checkbox"
                                        id="verified"
                                        checked={editingTool.verified}
                                        onChange={(e) => setEditingTool({ ...editingTool, verified: e.target.checked })}
                                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded dark:border-gray-600 dark:focus:ring-blue-500 dark:text-white"
                                    />
                                    <label htmlFor="verified" className="text-sm font-medium text-gray-700 dark:text-white">
                                        Verified Tool
                                    </label>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-white mb-1">Categories</label>
                                    <select
                                        multiple
                                        value={editingTool.categories}
                                        onChange={handleEditCategoryChange}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-white"
                                        required
                                        size={4}
                                    >
                                        {Object.entries(CATEGORIES).map(([category, categoryData]) => (
                                            <option key={category} value={category}>
                                                {category}
                                            </option>
                                        ))}
                                    </select>
                                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
                                        Hold Ctrl (Windows) or Command (Mac) to select multiple categories
                                    </p>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-white mb-1">Regions</label>
                                    <input
                                        type="text"
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)} // Update input value state
                                        onKeyDown={handleKeyDown} // Add key down handler
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-white"
                                        placeholder="Add country codes separated by space"
                                    />
                                    <div className="flex flex-wrap gap-1 mt-2">
                                        {editingTool.dataCountry?.map((region, index) => (
                                            <span key={index} className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                {region}
                                                <button
                                                    type="button"
                                                    onClick={() => handleDeleteRegion(index)}
                                                    className="ml-1 text-red-500 hover:text-red-700 transition-colors"
                                                >
                                                    &times;
                                                </button>
                                            </span>
                                        ))}
                                    </div>
                                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
                                        Press ENTER to add them to the field
                                    </p>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-white mb-1">Output option</label>
                                    <select
                                        value={editingTool.output_option}
                                        onChange={(e) => setEditingTool({ ...editingTool, output_option: e.target.value as "Run" | "Publish" | "Deploy" | "Test" | undefined })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-white"
                                    >
                                        <option value="">Select Output option</option>
                                        <option value="Run">Run</option>
                                        <option value="Publish">Publish</option>
                                        <option value="Deploy">Deploy</option>
                                    </select>
                                </div>


                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-white mb-1">Automation</label>
                                    <select
                                        value={editingTool.automation}
                                        onChange={(e) => setEditingTool({ ...editingTool, automation: e.target.value as "AI Engineer" | "Project automation" | "Task automation" | "Code completion" | undefined })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:text-white"
                                    >
                                        <option value="">Select Automation</option>
                                        <option value="AI Engineer">AI Engineer</option>
                                        <option value="Project automation">Project automation</option>
                                        <option value="Task automation">Task automation</option>
                                        <option value="Code completion">Code completion</option>
                                    </select>
                                </div>

                                <div className="flex gap-3 pt-4 sticky bottom-0 bg-white dark:bg-gray-800 py-4">
                                    <button
                                        type="submit"
                                        className="flex-1 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    >
                                        Save Changes
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setEditingTool(null)}
                                        className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {/* Delete Modal */}
                {isDeleteModalOpen && toolToDelete && (
                    <div 
                        className="fixed inset-0 bg-black/30 backdrop-blur-sm dark:bg-gray-800/30 flex items-center justify-center p-4 z-50"
                        onClick={(e) => {
                            // Close modal when clicking the backdrop
                            if (e.target === e.currentTarget) {
                                setIsDeleteModalOpen(false);
                                setToolToDelete(null);
                            }
                        }}
                    >
                        <div className="bg-white dark:bg-gray-900 rounded-lg shadow-xl max-w-sm w-full p-4">
                            <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Delete Tool</h2>
                            <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                                Are you sure you want to delete "{toolToDelete.name}"? This action cannot be undone.
                            </p>
                            <div className="flex gap-2">
                                <button
                                    onClick={() => handleDeleteTool(toolToDelete)}
                                    className="flex-1 inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:text-white dark:bg-red-600 dark:hover:bg-red-700"
                                >
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setIsDeleteModalOpen(false);
                                        setToolToDelete(null);
                                    }}
                                    className="flex-1 inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-gray-700 dark:text-white bg-gray-100 hover:bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:bg-gray-800 dark:hover:bg-gray-700"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
    </AppLayout>
  );
}
