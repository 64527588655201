import React from "react";
import {Link} from "react-router-dom";

interface MyComponentProps {
  url: String;
  name: String;
  targetBlank?: String;
}

const NavLink = (props: MyComponentProps) => {
    const active = props.url === window.location.pathname;

    if (props.targetBlank !== undefined) {
        return (
            <li className="w-full">
                <Link
                    className="md:px-4 font-sans py-2 text-sm bg-transparent rounded-lg text-[#666666] dark:text-white hover:text-gray-900 hover:dark:text-gray-400"
                    to={`${props.url}`}
                    target={`${props.targetBlank}`}
                >
                    {props.name}
                </Link>
            </li>
        );
    }
  return (
    <li className="w-full">
      <Link
        className={`block md:px-4 font-sans py-2 text-sm bg-transparent rounded-lg dark:text-white hover:text-gray-900 hover:dark:text-gray-400 ${active ? "text-sky-900 font-bold"  : "text-[#666666]"}`}
        to={`${props.url}`}
      >
        {props.name}
      </Link>
    </li>
  );
};

export default NavLink;
